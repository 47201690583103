import Vue from 'vue'
// eslint-disable-next-line no-unused-vars
import moment from 'moment'

Vue.filter('currency', number => (Number.isNaN(number) ? number : Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number)))
Vue.filter('decimal', number => (Number.isNaN(number) ? number : Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL' }).format(number)))

Vue.filter('phone', string => (string ? string.replace(/([0-9]{2})(.*)([0-9]{4})/, '($1) $2-$3') : string))
Vue.filter('cpf', string => (string ? string.replace(/([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/, '$1.$2.$3-$4') : string))
Vue.filter('cnpj', string => (string ? string.replace(/([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/, '$1.$2.$3/$4-$5') : string))
Vue.filter('formatPhone', string => String(string).replace(/[^0-9]/, '').replace(/([0-9]{2})([0-9]{4})([0-9]*)/, '($1) $2-$3'))

Vue.filter('ucfirst', string => {
  if (string.length > 0) {
    return `${string[0].toUpperCase()}${string.substring(1, string.length)}`
  }

  return ''
})

Vue.filter('date', (string, format) => {
  if (string !== null && string.length === 10) {
    return moment(String(string)).format(format === undefined ? 'DD/MM/YYYY' : format)
  }

  if (string !== null && string.length === 19) {
    return moment(String(string)).format(format === undefined ? 'DD/MM/YYYY HH:mm:ss' : format)
  }

  return string
})
