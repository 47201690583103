<template>
  <b-form-group
    label="Telefones Importados"
    label-for="registration_info_extracted_phones"
  >

    <b-spinner
      v-if="isLoadingExtractedPhones"
      small
    />
    <div v-else>

      <div v-if="extractedPhones.length === 0">
        Nenhum
      </div>
      <div
        v-for="phone in extractedPhones"
        v-else
        :key="phone.id"
        class="d-block"
      >
        <b-input-group>
          <b-form-input
            :id="`registration_extracted_phones_id${phone.id}`"
            :value="phone.phone"
            placeholder=""
            :disabled="true"
          />

          <b-input-group-append>
            <b-button
              variant="outline-primary"
              title="Remover telefone importado"
              :disabled="isSubmitingExtractedPhones"
              @click="removeExtractedPhone(phone)"
            >
              <b-spinner
                v-if="phone.submiting"
                small
              />
              <feather-icon
                v-else
                animation="spin-pulse"
                icon="XIcon"
              />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>

    </div>
  </b-form-group>
</template>

<script>
/* eslint-disable no-param-reassign */
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BFormInput,
  BSpinner,
  BButton,
  BInputGroupAppend,
  BInputGroup,
  BFormGroup,
} from 'bootstrap-vue'
import UserService from '@/services/userService'
import toast from '@/mixins/toast'

export default {
  components: {
    BFormInput,
    BSpinner,
    BInputGroup,
    BButton,
    BInputGroupAppend,
    BFormGroup,
  },
  mixins: [toast],
  props: {
    userID: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      userservice: null,
      isLoadingExtractedPhones: false,
      isSubmitingExtractedPhones: false,
      extractedPhones: [],
    }
  },
  computed: {},
  created() {
    this.userService = new UserService()
    this.getExtractedPhones()
  },
  methods: {
    getExtractedPhones() {
      this.isLoadingExtractedPhones = true

      this.userService.listExtractedPhones(this.$route.params.id).then(response => {
        if (response.status === 200) {
          this.extractedPhones = response.data.data.extracted_phones.map(m => ({
            ...m,
            submiting: false,
          }))
        }
      }).finally(() => {
        this.isLoadingExtractedPhones = false
      })
    },

    removeExtractedPhone(phone) {
      this.$swal({
        title: 'Invalidar telefone importado?',
        text: 'Marcar permanentemente este telefone como inválido?',
        icon: 'question',
        confirmButtonText: 'Sim, invalidar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isSubmitingExtractedPhones = true
          phone.submiting = true
          this.userService.removeExtractedPhone(phone.id).then(response => {
            this.isSubmitingExtractedPhones = false
            phone.submiting = false
            if (response.status) {
              this.extractedPhones = this.extractedPhones.filter(m => (m.id !== phone.id))
              this.toastSuccess('Telefone invalidado!')
            } else {
              this.toastDanger('Erro ao invalidar telefone!')
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
</style>
