import KanbanService from '@/services/kanbanService'

/* eslint-disable no-param-reassign */
export default {
  data() {
    return {
      report: [],
    }
  },
  methods: {
    async canSendEmail(_user, _loggedManager) {
      return this.isMyUser(_user, _loggedManager)
    },

    async canSendPaymentLink(_user, _loggedManager) {
      return this.isMyUser(_user, _loggedManager)
    },

    getRuleReport() {
      return this.report
    },

    async getCard(userId) {
      if (!userId) {
        return null
      }
      const kanbanService = new KanbanService()
      return kanbanService.getKanbanCard(userId).then(response => response.data.data.card)
    },

    async isMyUser(_user, _loggedManager) {
      if (!_user || !_loggedManager) {
        return false
      }

      this.report = []
      const now = new Date()
      const nowSub30 = new Date()
      const nowSub15 = new Date()
      nowSub15.setDate(now.getDate() - 15)
      nowSub30.setDate(now.getDate() - 30)
      const endOfPlan = _user.last_paid_quota ? new Date(_user.last_paid_quota.end_of_plan) : new Date()
      const sellerOfLastPlan = (_user.last_paid_quota && _user.last_paid_quota.account_manager_id) ? _user.last_paid_quota.account_manager_id : null
      // const courtesy = !_user.last_paid_quota || _user.quotas.plan_id === 5

      const userSales = _loggedManager.permission_type === 'sales'
      this.report.push(`Usuário do tipo sales: ${userSales ? 'sim' : '<strong>não</strong>'}`)
      if (!userSales) {
        return true
      }

      let accountManagerUntil = null
      if (_user.settings && _user.settings.account_manager_at !== undefined) {
        accountManagerUntil = new Date(_user.settings.account_manager_at)
        accountManagerUntil.setDate(accountManagerUntil.getDate() + 30)
      }

      const activeSignature = !!_user.last_paid_quota
          && (_user.last_paid_quota.type_of_acquisition === 'buy' || _user.last_paid_quota.type_of_acquisition === 'automatic')
          && endOfPlan >= now

      // se estiver na minha carteira
      //   Eu que vendi o Último plano
      //   Assinatura ativa ou vencida a menos de 15 dias
      const myUser = (sellerOfLastPlan === _loggedManager.id
          && activeSignature)

      this.report.push(`É meu: ${myUser ? '<strong class="text-success">sim</strong>' : 'não'}`)
      this.report.push(`  Está na minha carteira? ${sellerOfLastPlan === _loggedManager.id ? 'sim' : 'não'}`)
      this.report.push(`  Tem assinatura ativa? ${activeSignature ? 'sim' : 'não'}`)
      if (myUser) {
        return true
      }

      this.report.push(`Usuário possuí assinatura ativa? ${activeSignature ? '<strong class="text-danger">sim</strong>' : 'não'}`)
      if (activeSignature) {
        return false
      }

      const userHasAnotherEv = _user.settings && (
        _user.settings.account_manager_id
          && _loggedManager
          && _loggedManager.id !== _user.settings.account_manager_id
          && accountManagerUntil
          && accountManagerUntil > now
      )
      if (userHasAnotherEv) {
        if (endOfPlan < nowSub30) {
          this.report.push('Usuário está atribuído a outro EV? sim')
          this.report.push(`Plano vencido a mais de 30 dias? ${endOfPlan < nowSub30 ? '<strong class="text-success">sim</strong>' : '<strong class="text-danger">não</strong>'}`)
          this.report.push(`  Final do plano: ${endOfPlan.toLocaleString('pt-BR')}`)
          return true
        }
        this.report.push('Usuário está atribuído a outro EV? <strong class="text-danger">sim</strong>')
      } else {
        this.report.push('Usuário está atribuído a outro EV? não')

        this.report.push(`Plano vencido a mais de 15 dias? ${endOfPlan < nowSub15 ? '<strong class="text-success">sim</strong>' : 'não'}`)
        if (endOfPlan < nowSub15) {
          return true
        }

        this.report.push(`  Final do plano: ${endOfPlan.toLocaleString('pt-BR')}`)
        const kanbanCard = await this.getCard(_user.id)
        this.report.push(`Usuário está no meu kanban ? ${!!kanbanCard && _loggedManager && kanbanCard.account_manager_id === _loggedManager.id ? '<strong class="text-success">sim</strong>' : '<strong class="text-danger">não</strong>'}`)
        return !!kanbanCard && _loggedManager && kanbanCard.account_manager_id === _loggedManager.id
      }

      return false
    },
  },
}
