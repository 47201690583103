import useJwt from '@/auth/jwt/useJwt'
import LocalStorageService from '@/services/localStorageService'

export default class ZoneService {
  // eslint-disable-next-line class-methods-use-this
  list() {
    return useJwt.get('/zones')
  }

  // eslint-disable-next-line class-methods-use-this
  getAndStoreZones() {
    if (LocalStorageService.getArray(LocalStorageService.zones) !== null) {
      return new Promise(resolve => {
        resolve(LocalStorageService.getArray(LocalStorageService.zones))
      })
    }

    return this.list().then(response => {
      if (response.data !== null) {
        let { zones } = response.data.data
        zones = Object.values(zones).sort((a, b) => a.name.localeCompare(b.name))
        LocalStorageService.setArray(LocalStorageService.zones, zones)
        return zones
      }

      return []
    })
  }

  // eslint-disable-next-line class-methods-use-this
  apiCep(cep) {
    return useJwt.getExternal(`https://ws.apicep.com/cep/${cep}.json`).then(response => {
      if (response.status === 200 && response.data.status === 200) {
        return {
          logradouro: response.data.address,
          localidade: response.data.city,
          cep: response.data.code,
          bairro: response.data.district,
          uf: response.data.state,
          status: 200,
          mensagem: '',
        }
      }
      return {
        logradouro: '',
        localidade: '',
        cep: '',
        bairro: '',
        uf: '',
        status: 404,
        mensagem: (response.data.message && response.data.message !== '') ? response.data.message : 'CEP não encontado',
      }
    })
  }

  // eslint-disable-next-line class-methods-use-this
  getAddressByCep(cep) {
    return useJwt.getExternal(`https://viacep.com.br/ws/${cep}/json/`).then(response => {
      if (response.status === 200) {
        return response.data
      }
      return null
    })
  }

  // eslint-disable-next-line class-methods-use-this
  getAddressByAddress(state, city, street) {
    return useJwt.getExternal(`https://viacep.com.br/ws/${state}/${city}/${street}/json/`).then(response => {
      if (response.status === 200) {
        return response.data
      }
      return null
    })
  }
}
