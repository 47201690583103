export const maskDate = {
  date: true,
  delimiter: '/',
  datePattern: ['d', 'm', 'Y'],
}
export const maskPhone8 = {
  blocks: [0, 2, 4, 5],
  delimiters: ['(', ')', '-'],
}

export const maskPhone9 = {
  blocks: [0, 2, 1, 4, 4],
  delimiters: ['(', ')', ' ', '-'],
}

export const maskCep = {
  blocks: [5, 3],
  delimiters: ['-'],
}

export const maskTime = {
  blocks: [2, 2],
  delimiters: [':'],
}

export const maskCpf = {
  blocks: [3, 3, 3, 2],
  delimiters: ['.', '.', '-'],
}

export const maskCnpj = {
  blocks: [2, 3, 3, 4, 2],
  delimiters: ['.', '.', '/', '-'],
}

export default { }
