<template>
  <div class="list-conversations">
    <b-row>
      <b-col cols="12">
        <b-card-actions
          v-if="showFilters"
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="3">
              <b-form-group
                label="Usuário 1"
                label-for="user_id_1"
              >
                <b-form-input
                  id="user_id_1"
                  v-model="filtersValues.userId1"
                  placeholder="ID do usuário"
                  :disabled="limitFilters"
                  @keyup.enter="searchAndResetPage(1)"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                :disabled="filtersValues.type != 'conversa'"
                label="Usuário 2"
                label-for="user_id_2"
              >
                <b-form-input
                  id="user_id_2"
                  v-model="filtersValues.userId2"
                  :disabled="filtersValues.type != 'conversa'"
                  placeholder="ID do usuário"
                  @keyup.enter="searchAndResetPage(1)"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Tipo"
                label-for="filter_type"
              >
                <b-form-select
                  id="filter_type"
                  v-model="filtersValues.type"
                  :options="filtersOptions.type"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="3"
              class="form-buttons"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  @click="searchAndResetPage(1)"
                >
                  Buscar
                </b-button>

                <b-button
                  v-if="!limitFilters"
                  id="btn-limpar"
                  variant="outline-secondary"
                  @click="reset()"
                >
                  Limpar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-card>
              <b-card-title
                v-b-toggle.filter_collapse
                class="d-flex justify-content-between align-items-center m-0"
              >
                <div style="min-width: 250px;">
                  Mais Filtros
                </div>
                <feather-icon
                  icon="ChevronDownIcon"
                  size="16"
                  class="when-closed"
                />
                <feather-icon
                  icon="ChevronUpIcon"
                  size="16"
                  class="when-open"
                />
              </b-card-title>
              <b-collapse
                :id="`filter_collapse`"
              >
                <b-card-body class="p-0">

                  <div class="mt-1">
                    <b-form-checkbox
                      v-for="filter in moreFilters"
                      :key="filter.value"
                      v-model="filter.selected"
                      switch
                      :disabled="isLoading"
                      @change="(e) => { onFilter(e, filter.value) }"
                    >
                      {{ filter.text }}
                    </b-form-checkbox>
                  </div>

                </b-card-body>
              </b-collapse>
            </b-card>

          </b-row>
        </b-card-actions>

        <b-card
          ref="results"
          title="Resultados"
        >
          <b-row>
            <b-col cols="12">
              <b-table
                fixed
                class="table-responsive-xl"
                :busy="isLoading"
                :items="filteredResults"
                :fields="result_fields"
                sort-icon-left
                no-local-sorting
                :sort-by.sync="filtersValues.sortBy"
                :sort-desc.sync="filtersValues.sortDesc"
                @sort-changed="sortingChanged"
              >
                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #head(folder)>
                  <div class="text-nowrap">
                    Pasta
                  </div>
                </template>

                <template #head(sender)>
                  <div class="text-nowrap">
                    Remetente
                  </div>
                </template>

                <template #head(receiver)>
                  <div class="text-nowrap">
                    Destinatário
                  </div>
                </template>

                <template #head(send_on)>
                  <div class="text-nowrap">
                    Data de<br>
                    envio
                  </div>
                </template>

                <template #head(dates)>
                  <div class="text-nowrap">
                    Datas
                  </div>
                </template>

                <template #head(filters)>
                  <div class="text-nowrap">
                    Filtros
                  </div>
                </template>

                <template #head(finished)>
                  <div class="text-nowrap">
                    Status
                  </div>
                </template>

                <template #head(actions)>
                  <div class="text-nowrap">
                    Ações
                  </div>
                </template>

                <template #cell(dates)="row">
                  Lido <br />
                  {{ row.item.last_readed_at ? row.item.last_readed_at : row.item.readed_at }}
                  <br /><br />
                  Enviado: <br />
                  {{ row.item.send_on }}
                </template>

                <template #cell(filters)="row">
                  <div class="font-small-3">
                    {{ row.item.sender_is_active ? 'Connect-Chat' : '' }}<br />
                    {{ row.item.responded ? 'Respondida' : '' }}
                  </div>
                </template>

                <template #cell(folder)="row">
                  <div class="text-nowrap">
                    {{ $t(`userMessages.folders.${row.item.folder}`) }}
                  </div>
                </template>

                <template #cell(finished)="row">
                  <div class="text-nowrap">
                    {{ row.item.finished }}
                  </div>
                </template>

                <template #cell(sender)="row">
                  <b-button
                    variant="secondary"
                    text-variant="white"
                    :to="{ name: 'cms-user-form-update', params: { id: row.item.sender } }"
                    target="_blank"
                    class="d-flex align-items-start justify-content-start text-left m-0"
                  >
                    <b-avatar
                      size="40"
                      :src="row.item.sender_avatar ? row.item.sender_avatar.sm : ''"
                      variant="light-primary"
                      class="mr-1"
                    >
                      <feather-icon
                        v-if="!row.item.sender_name"
                        icon="UserIcon"
                        size="22"
                      />
                    </b-avatar>
                    <div>
                      ({{ row.item.sender }})<br>
                      {{ row.item.sender_name }}
                    </div>
                  </b-button>
                </template>

                <template #cell(receiver)="row">
                  <b-button
                    variant="secondary"
                    text-variant="white"
                    :to="{ name: 'cms-user-form-update', params: { id: row.item.receiver } }"
                    target="_blank"
                    class="d-flex align-items-start justify-content-start text-left m-0"
                  >
                    <b-avatar
                      size="40"
                      :src="row.item.receiver_avatar ? row.item.receiver_avatar.sm : ''"
                      variant="light-primary"
                      class="mr-1"
                    >
                      <feather-icon
                        v-if="!row.item.receiver_name"
                        icon="UserIcon"
                        size="22"
                      />
                    </b-avatar>
                    <div>
                      ({{ row.item.receiver }})<br>
                      {{ row.item.receiver_name }}
                    </div>
                  </b-button>
                </template>

                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-dropdown
                      :id="row.item.id.toString()"
                      no-caret
                      variant="link"
                      text="..."
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item
                        @click="openMessage( row )"
                      >
                        Ver mensagem
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="openDirectLink( row )"
                      >
                        Gerar link direto
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="$can('read', 'Message') && row.item.finished === 'Aberta'"
                        @click="sendMessageLink( row.item.conversation_id )"
                      >
                        Enviar link direto
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="$can('update', 'Conversation')"
                        @click="closeConversation( row.item.conversation_id )"
                      >
                        Encerrar conversa
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="$can('delete', 'Message')"
                        @click="deleteMessage( row.item.id )"
                      >
                        Deletar mensagem
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>

                <template #row-details="row">
                  <b-spinner v-if="!row.item.details" />
                  <p v-else-if="row.item.details.message">
                    {{ row.item.details.message }}
                  </p>
                  <div v-else-if="row.item.details.link">
                    <b-alert
                      show
                      variant="danger"
                      class="mb-1 p-1"
                    >
                      Esse link de mensagem deve ser enviado para
                      <b>{{ row.item.details.receiver.name }}({{ row.item.details.receiver.email }})</b>
                      pois a última mensagem nessa conversa foi enviada por
                      <b>{{ row.item.details.sender.name }}({{ row.item.details.sender.email }})</b>
                    </b-alert>
                    <b-form-input
                      :value="row.item.details.link"
                      readonly
                      size="sm"
                      style="opacity: 1"
                      @focus.native="$event.target.select()"
                    />
                  </div>
                </template>

              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <!-- pagination nav -->
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page === undefined ? 99 : pages.last_page"
                :link-gen="() => { return '#' }"
                align="end"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>

    </b-row>

    <b-modal
      id="modal-close-message"
      title="Encerrar conversa"
      ok-title="Encerrar conversa"
      cancel-title="voltar"
      cancel-variant="outline-secondary"
      @ok="handleCloseConversation()"
    >
      <h2>Selecione o motivo para encerrar a conversa</h2>
      <b-form-group>
        <v-select
          v-model="closeMessageSelected"
          :options="closeMessageOptions"
          label="title"
        />
      </b-form-group>
    </b-modal>

    <b-modal
      id="modal-send-message-link"
      title="Enviar link direto"
      no-close-on-esc
      no-close-on-backdrop
      :hide-header-close="isSubmitting"
    >
      <p>
        Você está prestes a enviar o link da mensagem para o destinatário:
      </p>
      <b-card
        v-if="modalMessage"
        bg-variant="dark"
        text-variant="white"
      >
        <div
          class="d-flex align-items-start justify-content-start"
        >
          <b-avatar
            size="40"
            :src="modalMessage.receiver_avatar ? modalMessage.receiver_avatar.sm : ''"
            variant="light-primary"
            class="mr-1"
          >
            <feather-icon
              v-if="!modalMessage.receiver_name"
              icon="UserIcon"
              size="22"
            />
          </b-avatar>
          <div>
            ({{ modalMessage.receiver }})<br>
            {{ modalMessage.receiver_name }}
          </div>
        </div>
      </b-card>
      <p>
        Por quais meios você deseja realizar o envio do link da mensagem?
      </p>
      <b-form-checkbox
        v-for="channel in Object.keys(channelsToSendLink)"
        :key="channel"
        v-model="channelsToSendLink[channel]"
        :disabled="isSubmitting"
        switch
      >
        {{ formatCamelCase(channel) }}
      </b-form-checkbox>

      <template #modal-footer>
        <button
          type="button"
          class="btn btn-outline-secondary"
          :disabled="isSubmitting"
          @click.prevent="$bvModal.hide('modal-send-message-link')"
        >
          Voltar
        </button>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="isSubmitting"
          @click.prevent="handleSendMessageLink()"
        >
          Enviar
          <b-spinner
            v-if="isSubmitting"
            small
          />
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ConnectionService from '@/services/connectionService'
import {
  BAvatar,
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BPaginationNav,
  BSpinner,
  BButton,
  BModal,
  BCard,
  BAlert,
  BCollapse,
  BCardTitle,
  VBToggle,
  BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatCamelCase, formatDateTimeDbToView } from '@/utils/helpers'

export default {
  components: {
    BCardBody,
    BCardTitle,
    BCollapse,
    BAlert,
    BCardActions,
    BCard,
    BAvatar,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BPaginationNav,
    BSpinner,
    BButton,
    BModal,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },

  props: {
    showFilters: {
      type: Boolean,
      default: true,
    },

    limitFilters: {
      type: Boolean,
      default: false,
    },

    filters: {
      type: Object,
      default() { return {} },
    },
  },

  data() {
    return {
      isLoading: true,
      connectionService: null,
      modalConversationId: 0,
      closeMessageSelected: {},
      closeMessageOptions: [
        { title: 'Fechamos a parceria', value: 'Fechamos a parceria' },
        { title: 'Foge ao Perfil', value: 'Foge ao Perfil' },
        { title: 'Não tenho interesse', value: 'Não tenho interesse' },
        { title: 'Outro', value: 'Outro' },
        { title: 'Pirâmide', value: 'Pirâmide' },
        { title: 'Propaganda/Venda de Serviços', value: 'Propaganda/Venda de Serviços' },
        { title: 'Sem retorno', value: 'Sem retorno' },
      ],
      moreFilters: [
        { text: 'Todos', value: 'all', selected: true },
        { text: 'Mensagens Respondidas', value: 'responded', selected: true },
        { text: 'Mensagens não respondidas', value: 'dont-responded', selected: true },
        { text: 'Assinatura Ativa (Connect Chat)', value: 'connect-chat', selected: true },
      ],
      filtersInitialValues: {},
      filtersValues: {
        userId1: '',
        userId2: '',
        type: 'enviadas',
        sortBy: 'id',
        sortDesc: true,
      },
      filtersOptions: {
        type: [
          { value: 'enviadas', text: 'Enviadas' },
          { value: 'recebidas', text: 'Recebidas' },
          { value: 'conversa', text: 'Conversa' },
        ],
      },

      requested: {
        type: '',
      },

      pages: 1,
      currentPage: 1,
      results: [],
      formatDateTimeDbToView,
      formatCamelCase,
      channelsToSendLink: {
        email: false,
        whatsapp: false,
      },
      isSubmitting: false,
    }
  },

  computed: {
    filteredResults() {
      const filters = this.moreFilters.filter(f => f.selected).map(m => m.value)

      return this.results.filter(f => (
        filters.includes('all')
          || (filters.includes('connect-chat') && f.sender_is_active)
          || (filters.includes('dont-responded') && !f.responded)
          || (filters.includes('responded') && f.responded)
      ))
    },

    modalMessage() {
      if (this.modalConversationId) {
        return this.results.find(f => f.conversation_id === this.modalConversationId)
      }

      return null
    },
    result_fields() {
      let includeReceiver = false
      let includeSender = false
      if (!this.requested.userId1 && !this.requested.userId2) {
        includeReceiver = true
        includeSender = true
      } else if (this.requested.type === 'enviadas') {
        includeReceiver = true
      } else {
        includeSender = true
      }

      const resultFields = [
        { key: 'id', sortable: true, thStyle: 'vertical-align: middle;width: 100px;' },
        { key: 'folder', sortable: true, thStyle: 'vertical-align: middle;width: 100px;' },
        ...includeSender ? [{
          key: 'sender', sortable: true, thStyle: 'vertical-align: middle;', tdClass: 'text-break',
        }] : [],
        ...includeReceiver ? [{
          key: 'receiver', sortable: true, thStyle: 'vertical-align: middle;', tdClass: 'text-break',
        }] : [],
        { key: 'finished', sortable: true, thStyle: 'vertical-align: middle;width: 100px;' },
        { key: 'dates', sortable: true, thStyle: 'vertical-align: middle; width: 110px;' },
        { key: 'filters', sortable: true, thStyle: 'vertical-align: middle; width: 120px;' },
        { key: 'actions', sortable: false, thStyle: 'vertical-align: middle;width: 100px' },
      ]

      return resultFields
    },
  },

  watch: {
    currentPage() {
      this.getConversations()
    },
  },

  created() {
    if (this.filters) {
      if (this.filters.type) {
        this.filtersValues.type = this.filters.type
      }

      if (this.filters.userId1) {
        this.filtersValues.userId1 = this.filters.userId1
      }

      if (this.filters.userId2) {
        this.filtersValues.userId2 = this.filters.userId2
      }
    }

    this.connectionService = new ConnectionService()
    this.getConversations()
    this.filtersInitialValues = { ...this.filtersValues }
  },
  methods: {
    onFilter(e, label) {
      this.currentPage = 1

      if (label === 'all') {
        this.moreFilters = this.moreFilters.map(m => ({
          ...m,
          selected: e,
        }))
      } else {
        const all = (this.moreFilters.filter(f => f.selected && f.value !== 'all').length === this.moreFilters.filter(f => f.value !== 'all').length)
        this.moreFilters.find(f => f.value === 'all').selected = all
      }
    },

    reset() {
      this.filtersValues = JSON.parse(JSON.stringify(this.filtersInitialValues))
      this.currentPage = 1
      this.getConversations()
    },

    searchAndResetPage() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.getConversations()
      }
    },

    sortingChanged(ctx) {
      this.filtersValues.sortBy = ctx.sortBy
      this.filtersValues.sortDesc = ctx.sortDesc
      this.getConversations()
    },

    handleCloseConversation() {
      if (this.closeMessageSelected === null) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'A conversa não foi encerrada!',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Selecione corretamenre um dos motivos listados',
          },
        })
      } else {
        this.connectionService.closeMessage(this.modalConversationId, this.closeMessageSelected.value).then(response => {
          if (response.status === 200) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Conversa ${this.modalConversationId} encerrada!`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                // text: `!`,
              },
            })

            this.modalConversationId = 0
          }
        }).catch(response => {
          if (response.request.status === 400) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ops!',
                icon: 'BellIcon',
                variant: 'danger',
                text: response.response.data.errors.join('\n'),
              },
            })
          }
        })
      }
    },

    closeConversation(conversationId) {
      this.modalConversationId = conversationId
      // eslint-disable-next-line prefer-destructuring
      this.closeMessageSelected = this.closeMessageOptions[0]
      this.$bvModal.show('modal-close-message')
    },

    sendMessageLink(conversationId) {
      this.modalConversationId = conversationId
      this.$bvModal.show('modal-send-message-link')
    },

    handleSendMessageLink() {
      this.isSubmitting = true
      this.connectionService.sendMessageLink(this.modalConversationId, {
        'channels[]': Object.keys(this.channelsToSendLink).filter(channel => this.channelsToSendLink[channel]),
      }).then(response => {
        this.isSubmitting = false
        if (response.status === 200) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Envio de link solicitado!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })

          this.$bvModal.hide('modal-send-message-link')

          return
        }

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ops!',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Ocorreu um erro inesperado',
          },
        })
      }).catch(response => {
        this.isSubmitting = false
        if (response.response.data.errors) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops!',
              icon: 'BellIcon',
              variant: 'danger',
              text: response.response.data.errors.join('\n'),
            },
          })

          return
        }

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ops!',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Ocorreu um erro inesperado',
          },
        })
      })
    },

    /* eslint-disable no-param-reassign */
    openMessage(row) {
      const messageId = row.item.id
      this.results[row.index].details = null
      if (!row.detailsShowing) {
        row.toggleDetails()
      }
      this.results = [...this.results]
      this.connectionService.getMessage(messageId).then(response => {
        if (response.status === 200) {
          this.results[row.index].details = {
            message: response.data.data.message.body,
          }
          this.results = [...this.results]
        }
      })
    },

    openDirectLink(row) {
      const conversationId = row.item.conversation_id
      this.results[row.index].details = null
      if (!row.detailsShowing) {
        row.toggleDetails()
      }
      this.results = [...this.results]
      this.connectionService.getDirectLink(conversationId).then(response => {
        if (response.status === 200) {
          this.results[row.index].details = response.data.data.message
          this.results = [...this.results]
        }
      })
    },
    /* eslint-enable no-param-reassign */

    deleteMessage(messageId) {
      this.$swal({
        title: 'Deletar mensagem?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.connectionService.deleteMessage(messageId).then(response => {
            if (response.status === 200) {
              this.results = this.results.filter(filter => filter.id !== messageId)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Mensagem ${messageId} removida!`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  // text: `!`,
                },
              })
            }
          })
        }
      })
    },

    getConversations() {
      if (this.filtersValues.type === 'conversa' && (this.filtersValues.userId1 === '' || this.filtersValues.userId2 === '')) {
        this.$swal({
          title: 'Ops!',
          text: 'Para buscar mensagens do tipo \'conversa\', deve-se preencher os campos \'usuário 1\' e \'usuário 2\'',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }

      if (this.filtersValues.sortBy !== 'id' && this.filtersValues.userId1 === '' && this.filtersValues.userId2 === '') {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro na ordenação!',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'A ordenação de resultados só é possível quando algum usuário for filtrado',
          },
        })
      }

      this.isLoading = true
      this.requested = { ...this.filtersValues }
      this.connectionService.getMessages(this.filtersValues.userId1, this.filtersValues.userId2, this.filtersValues.type, this.currentPage, this.filtersValues.sortBy, this.filtersValues.sortDesc).then(response => {
        if (response.data !== null) {
          const messages = response.data.data.messages.data
          this.results = messages.map(map => ({
            ...map,
            sender: map.sender_type === 'company' ? map.company_user_id : map.rep_user_id,
            receiver: map.sender_type === 'company' ? map.rep_user_id : map.company_user_id,
            readed_at: this.formatDateTimeDbToView(map.readed_at),
            last_readed_at: this.formatDateTimeDbToView(map.last_readed_at),
            finished: map.finished === 1 ? 'Encerrada' : 'Aberta',
            send_on: this.formatDateTimeDbToView(map.send_on),
            message: '',
          }))
          this.pages = response.data.data.messages
          delete this.pages.data
        }
        this.isLoading = false
      }).catch(response => {
        if (response.response.data.errors) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops!',
              icon: 'BellIcon',
              variant: 'danger',
              text: response.response.data.errors.join('\n'),
            },
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .collapsed > .when-open,
  .not-collapsed > .when-closed {
   display: none;
  }

  .list-conversations {
    .b-table{
      td{
        padding: 0.5rem 1rem;
      }
    }

    .form-buttons button {
      margin-right: 10px;
    }

    table {
      width: 100%;
    }
  }
</style>
