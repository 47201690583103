import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import UserEditZones from '@/views/cms/components/UserEditZones.vue'
import UserEditSegments from '@/views/cms/components/UserEditSegments.vue'
import UserExtractedPhones from '@/views/cms/components/UserExtractedPhones.vue'
import UserEditPhones from '@/views/cms/components/UserEditPhones.vue'
import UserEditEmails from '@/views/cms/components/UserEditEmails.vue'
import UserListConversations from '@/views/cms/components/UserListConversations.vue'
import UserListFinancial from '@/views/cms/components/UserListFinancial.vue'
import UserListTaxData from '@/views/cms/components/UserListTaxData.vue'
import UserListQuotas from '@/views/cms/components/UserListQuotas.vue'
import UserListSettings from '@/views/cms/components/UserListSettings.vue'
import UserListDemographic from '@/views/cms/components/UserListDemographic.vue'
import UserListFavorites from '@/views/cms/components/UserListFavorites.vue'

Vue.component(
  FeatherIcon.name,
  FeatherIcon,
  UserEditZones,
  UserEditSegments,
  UserExtractedPhones,
  UserEditPhones,
  UserEditEmails,
  UserListConversations,
  UserListFinancial,
  UserListTaxData,
  UserListQuotas,
  UserListSettings,
  UserListDemographic,
  UserListFavorites,
)
