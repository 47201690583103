<template xmlns:b-row="http://www.w3.org/1999/html">
  <b-card :class="{'no-padding': noPadding}">
    <b-modal
      ref="audits"
      title="Histórico"
      size="lg"
      ok-only
    >
      <audits
        auditable-type="demographic"
        :auditable-id="userId"
        :no-title="true"
      />
    </b-modal>

    <div
      v-if="isLoading"
      class="text-center"
    >
      <b-spinner />
      <p>Carregando...</p>
    </div>

    <b-form v-if="!isLoading">
      <validation-observer ref="rulesDemographic">

        <b-row>
          <b-col>
            <h4>Demográfico</h4>
          </b-col>

          <b-col class="text-right">
            <b-button @click="openAudits">
              Histórico
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <!-- todos - falei com -->
            <b-form-group
              label="Falei com"
              label-for="talked_with"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-select
                  id="talked_with"
                  v-model="results.talked_with"
                  :options="userDemographicsTalkedWith"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                  @change="selectedTalkedWith"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- todos - responsavel -->
            <b-form-group
              label="Responsável"
              label-for="responsible"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                :rules="sidebarRole === 'sales' ? '' : 'required'"
              >
                <b-form-input
                  v-model="results.responsible"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- todos - responsavel outros -->
            <b-form-group
              v-if="results.talked_with === 'other'"
              label="Cargo"
              label-for="position_in_company"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                :rules="sidebarRole === 'sales' ? '' : 'required'"
              >
                <b-form-input
                  v-model="results.position_in_company"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- empresa - estrutura comercial - vendas e sucesso -->
            <b-form-group
              v-if="userType === 'company'"
              label="Estrutura Comercial (quantas pessoas dedicadas no Dept. Comercial)?"
              label-for="commercial_team_size"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                :rules="sidebarRole === 'sales' ? '' : 'required'"
              >
                <b-form-select
                  id="commercial_team_size"
                  v-model="results.commercial_team_size"
                  :options="userDemographicCommercialTeamSize"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- todos - notas - vendas e sucesso -->
            <b-form-group
              :label="sidebarRole === 'sales' ? 'Anotação do contato' : 'Notas'"
              label-for="notes"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-textarea
                  id="notes"
                  v-model="results.notes"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                  rows="3"
                  max-rows="8"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <hr v-if="sidebarRole === ''">
            <h3 v-if="sidebarRole === ''">
              Exclusivo de Sucesso
            </h3>

            <!-- todos - objetivos - sucesso -->
            <b-form-group
              v-if="['relationship', 'success', ''].includes(sidebarRole)"
              label="Objetivos"
              label-for="objective"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                :rules="sidebarRole === 'sales' ? 'required' : ''"
              >
                <b-form-select
                  id="objective"
                  v-model="results.objective"
                  :options="userDemographicObjectives"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-col
                v-if="results.objective === 'other'"
                class="mt-1"
                cols="4"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-input
                    v-model="results.objective_other"
                    placeholder="Qual objetivo?"
                    :disabled="isSubmiting"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-form-group>

            <!-- empresa - tamanho da empresa - sucesso -->
            <b-form-group
              v-if="userType === 'company' && ['relationship', 'success', ''].includes(sidebarRole)"
              label="Tamanho da empresa"
              label-for="company_size"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                :rules="sidebarRole === 'sales' ? 'required' : ''"
              >
                <b-form-select
                  id="company_size"
                  v-model="results.company_size"
                  :options="userDemographicCompanySize"
                  :disabled="isSubmiting"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- empresa - experiencia com representantes - sucesso -->
            <b-form-group
              v-if="userType === 'company' && ['relationship', 'success', ''].includes(sidebarRole)"
              label="Empresa tem experiência com Representantes?"
              label-for="experience_with_representatives"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                :rules="sidebarRole === 'sales' ? 'required' : ''"
              >
                <b-form-select
                  id="experience_with_representatives"
                  v-model="results.experience_with_representatives"
                  :options="userDemographicExperienceWithRepresentatives"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- representante - experiencia com representação - sucesso -->
            <b-form-group
              v-if="userType === 'representative' && ['relationship', 'success', ''].includes(sidebarRole)"
              label="Experiência com representação"
              label-for="experience_with_representation"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                :rules="sidebarRole === 'sales' ? 'required' : ''"
              >
                <b-form-select
                  id="experience_with_representation"
                  v-model="results.experience_with_representation"
                  :options="representativeDemographicExperienceWithRepresentation"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- representante - empresa constituida - sucesso -->
            <b-form-group
              v-if="userType === 'representative' && results.experience_with_representation !== 'without_experience' && ['relationship', 'success', ''].includes(sidebarRole)"
              label="Empresa Constituída"
              label-for="constituted_company"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                :rules="sidebarRole === 'sales' ? 'required' : ''"
              >
                <b-form-select
                  id="constituted_company"
                  v-model="results.constituted_company"
                  :options="representativeDemographicConstitutedCompany"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- representante - core - sucesso -->
            <b-form-group
              v-if="userType === 'representative' && results.experience_with_representation !== 'without_experience' && ['relationship', 'success', ''].includes(sidebarRole)"
              label="Core"
              label-for="core"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                :rules="sidebarRole === 'sales' ? 'required' : ''"
              >
                <b-form-select
                  id="core"
                  v-model="results.core"
                  :options="representativeDemographicCore"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- representante - quantidade de representadas - sucesso -->
            <b-form-group
              v-if="userType === 'representative' && results.experience_with_representation !== 'without_experience' && ['relationship', 'success', ''].includes(sidebarRole)"
              label="Quantidade de representadas(pastas)"
              label-for="quantity_represented"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                :rules="sidebarRole === 'sales' ? 'required' : ''"
              >
                <b-form-select
                  id="quantity_represented"
                  v-model="results.quantity_represented"
                  :options="representativeDemographicQuantityRepresented"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- representante - carteira de clientes - sucesso -->
            <b-form-group
              v-if="userType === 'representative' && results.experience_with_representation !== 'without_experience' && ['relationship', 'success', ''].includes(sidebarRole)"
              label="Carteira de clientes"
              label-for="portfolio"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                :rules="sidebarRole === 'sales' ? 'required' : ''"
              >
                <b-form-select
                  id="portfollio"
                  v-model="results.portfolio"
                  :options="representativeDemographicPortfolio"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- todos - crm - sucesso -->
            <b-form-group
              v-if="(userType === 'company' || results.experience_with_representation !== 'without_experience') && ['relationship', 'success', ''].includes(sidebarRole)"
              label="CRM?"
              label-for="crm"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                :rules="sidebarRole === 'sales' ? 'required' : ''"
              >
                <b-form-select
                  id="crm"
                  v-model="results.crm"
                  :options="userDemographicCrm"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <b-col
                v-if="results.crm === '1'"
                class="mt-1"
                cols="4"
              >
                <b-form-input
                  id="crm_detail"
                  v-model="results.crm_detail"
                  placeholder="Qual CRM?"
                  :disabled="isSubmiting"
                />
              </b-col>
            </b-form-group>

            <!-- todos - maturidade digital - sucesso -->
            <b-form-group
              v-if="['relationship', 'success', ''].includes(sidebarRole)"
              label="Maturidade Digital do responsável"
              label-for="digital_maturity"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                :rules="sidebarRole === 'sales' ? 'required' : ''"
              >
                <b-form-select
                  id="digital_maturity"
                  v-model="results.digital_maturity"
                  :options="userDemographicDigitalMaturity"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- todos - aderência à nossa proposta - sucesso -->
            <b-form-group
              v-if="['relationship', 'success', ''].includes(sidebarRole)"
              label="Aderência à nossa proposta"
              label-for="adherence_to_the_proposal"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                :rules="sidebarRole === 'sales' ? 'required' : ''"
              >
                <b-form-select
                  id="adherence_to_the_proposal"
                  v-model="results.adherence_to_the_proposal"
                  :options="userDemographicAdherenceToTheProposal"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <hr v-if="sidebarRole === ''">
            <h3 v-if="sidebarRole === ''">
              Exclusivo de Vendas
            </h3>

            <!-- todos - Momento comercial - vendas -->
            <b-form-group
              v-if="['sales', ''].includes(sidebarRole)"
              label="Momento comercial"
              label-for="commercial_moment"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
              >
                <b-form-textarea
                  id="commercial_moment"
                  v-model="results.commercial_moment"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                  rows="3"
                  max-rows="8"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- todos - Dores - vendas -->
            <b-form-group
              v-if="['sales', ''].includes(sidebarRole)"
              label="Dores"
              label-for="pains"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
              >
                <b-form-textarea
                  id="pains"
                  v-model="results.pains"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                  rows="3"
                  max-rows="8"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- todos - crescimento/substituição - vendas -->
            <b-form-group
              v-if="['sales', ''].includes(sidebarRole)"
              label="Crescimento/Substituição"
              label-for="growth_replacement"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
                rules=""
              >
                <b-form-select
                  id="growth_replacement"
                  v-model="results.growth_replacement"
                  :options="userDemographicGrowthReplacement"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- todos - planejamento - vendas -->
            <b-form-group
              v-if="['sales', ''].includes(sidebarRole)"
              label="Planejamento"
              label-for="planning"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
              >
                <b-form-select
                  id="planning"
                  v-model="results.planning"
                  :options="userDemographicPlanning"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- todos - Percepção de Urgência - vendas -->
            <b-form-group
              v-if="['sales', ''].includes(sidebarRole)"
              label="Percepção de Urgência"
              label-for="is_urgent"
              :label-cols-md="labelCols"
            >
              <validation-provider
                #default="{ errors }"
              >
                <b-form-select
                  id="is_urgent"
                  v-model="results.is_urgent"
                  :options="userDemographicIsUrgent"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isSubmiting"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>

        <b-row v-if="saveButton">
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              align="right"
              variant="primary"
              type="submit"
              :disabled="isSubmiting || isLoading"
              @click.prevent="updateDemographic()"
            >
              Salvar
              <b-spinner
                v-if="isSubmiting"
                small
              />
            </b-button>
          </b-col>
        </b-row>

      </validation-observer>

    </b-form>
  </b-card>
</template>

<script>
import { required } from '@validations'
import UserService from '@/services/userService'
import toast from '@/mixins/toast'
import {
  userDemographicsTalkedWith,
  companyDemographicObjectives,
  representativeDemographicObjectives,
  representativeDemographicConstitutedCompany,
  representativeDemographicCore,
  representativeDemographicQuantityRepresented,
  representativeDemographicPortfolio,
  userDemographicCompanySize,
  userDemographicExperienceWithRepresentatives,
  representativeDemographicExperienceWithRepresentation,
  userDemographicCommercialTeamSize,
  userDemographicCrm,
  userDemographicDigitalMaturity,
  userDemographicAdherenceToTheProposal,
  userDemographicGrowthReplacement,
  userDemographicPlanning,
  userDemographicIsUrgent,
} from '@/utils/options'
import {
  BButton,
  BCol,
  BRow,
  BForm,
  BFormSelect,
  BFormInput,
  BFormGroup,
  BModal,
  BFormTextarea,
  BSpinner,
  BCard,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Audits from './Audits.vue'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BForm,
    BFormSelect,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BModal,
    BCard,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    Audits,
  },

  mixins: [
    toast,
  ],

  props: {
    userResponsible: {
      type: String,
      default: '',
    },

    sidebarRole: {
      type: String,
      default: '',
    },

    userId: {
      type: Number,
      default: null,
    },

    userType: {
      type: String,
      default: 'company',
    },

    saveButton: {
      type: Boolean,
      default: true,
    },

    noPadding: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isSubmiting: false,
      userData: null,
      isLoading: true,
      userService: null,
      initExecuted: false,
      settings: '',
      results: {
        adherence_to_the_proposal: '',
        commercial_team_size: '',
        company_size: '',
        created_at: '',
        crm: '',
        crm_detail: '',
        digital_maturity: '',
        experience_with_representatives: '',
        experience_with_representation: '',
        constituted_company: '',
        core: '',
        quantity_represented: '',
        portfolio: '',
        notes: '',
        objective: '',
        talked_with: '',
        responsible: '',
        position_in_company: '',
        commercial_moment: '',
        pains: '',
        growth_replacement: '',
        goal: '',
        planning: '',
        is_urgent: '',
        updated_at: '',
        user_id: '',
      },
      required,
      userDemographicsTalkedWith: [
        { value: '', text: 'Não informado' },
        ...userDemographicsTalkedWith,
      ],
      userDemographicCompanySize: [
        { value: '', text: 'Não informado' },
        ...userDemographicCompanySize,
      ],
      userDemographicExperienceWithRepresentatives: [
        { value: '', text: 'Não informado' },
        ...userDemographicExperienceWithRepresentatives,
      ],
      representativeDemographicExperienceWithRepresentation: [
        { value: '', text: 'Não informado' },
        ...representativeDemographicExperienceWithRepresentation,
      ],
      userDemographicCommercialTeamSize: [
        { value: '', text: 'Não informado' },
        ...userDemographicCommercialTeamSize,
      ],
      userDemographicCrm: [
        { value: '', text: 'Não informado' },
        ...userDemographicCrm,
      ],
      userDemographicDigitalMaturity: [
        { value: '', text: 'Não informado' },
        ...userDemographicDigitalMaturity,
      ],
      userDemographicAdherenceToTheProposal: [
        { value: '', text: 'Não informado' },
        ...userDemographicAdherenceToTheProposal,
      ],
      representativeDemographicConstitutedCompany: [
        { value: '', text: 'Não informado' },
        ...representativeDemographicConstitutedCompany,
      ],
      representativeDemographicCore: [
        { value: '', text: 'Não informado' },
        ...representativeDemographicCore,
      ],
      representativeDemographicQuantityRepresented: [
        { value: '', text: 'Não informado' },
        ...representativeDemographicQuantityRepresented,
      ],
      representativeDemographicPortfolio: [
        { value: '', text: 'Não informado' },
        ...representativeDemographicPortfolio,
      ],
      userDemographicGrowthReplacement: [
        { value: '', text: 'Não informado' },
        ...userDemographicGrowthReplacement,
      ],
      userDemographicPlanning: [
        { value: '', text: 'Não informado' },
        ...userDemographicPlanning,
      ],
      userDemographicIsUrgent: [
        { value: '', text: 'Não informado' },
        ...userDemographicIsUrgent,
      ],
    }
  },

  computed: {
    labelCols() {
      if (this.sidebarRole === '') {
        return '3'
      }

      return '0'
    },

    userDemographicObjectives() {
      if (this.userType === 'company') {
        return [
          { value: '', text: 'Não informado' },
          ...companyDemographicObjectives,
        ]
      }

      return [
        { value: '', text: 'Não informado' },
        ...representativeDemographicObjectives,
      ]
    },
  },

  watch: {
    userResponsible() {
      this.setResponsibleName()
    },
  },

  async created() {
    this.userService = new UserService()
    const userDataString = await localStorage.getItem('userData')
    this.userData = await JSON.parse(userDataString)
  },

  methods: {
    init() {
      if (!this.initExecuted) {
        this.getData(this.userId)
        this.initExecuted = true
        this.setResponsibleName()
      }
    },

    setResponsibleName() {
      this.userDemographicsTalkedWith = this.userDemographicsTalkedWith.map(m => {
        if (m.value === 'responsible') {
          // eslint-disable-next-line no-param-reassign
          m.text = `Responsável pelo Cadastro (${this.userResponsible})`
        }
        return m
      })
    },

    selectedTalkedWith(value) {
      if (value === 'responsible') {
        this.results.responsible = this.userResponsible
        this.results.position_in_company = ''
      }
    },

    openAudits() {
      this.$refs.audits.show()
    },

    getData(userId) {
      this.isLoading = true
      this.$emit('isLoading', true)
      this.userService.getDemographic(userId).then(response => {
        if (response.status === 200 && response.data.data.demographic) {
          this.results = Object.fromEntries(Object.entries(response.data.data.demographic).map(m => {
            const ret = m
            ret[1] = ret[1] ?? ''
            return ret
          }))

          if (this.sidebarRole !== '') {
            this.results.notes = ''
          }
        }
      }).catch(() => {
        this.toastDanger('Ops!', 'Aconteceu um erro ao obter os dados demográficos')
      }).finally(() => {
        this.isLoading = false
        this.$emit('isLoading', false)
      })
    },

    async postDemographic() {
      return this.$refs.rulesDemographic.validate().then(success => {
        if (!success) {
          this.toastDanger('Ops - Existem erros de validação')
          return Promise.reject(new Error('Existem erros de validação'))
        }

        this.isSubmiting = true

        let fields = []

        if (['sales', ''].includes(this.sidebarRole)) {
          fields = [
            ...fields,
            'talked_with',
            'responsible',
            'commercial_moment',
            'pains',
            'growth_replacement',
            'goal',
            'planning',
            'is_urgent',
            'notes',
          ]
        }

        if (['relationship', 'success', ''].includes(this.sidebarRole)) {
          fields = [
            ...fields,
            'objective',
            'talked_with',
            'responsible',
            'digital_maturity',
            'adherence_to_the_proposal',
            'crm',
            'crm_detail',
            'notes',
          ]
        }

        if (this.userType === 'company') {
          fields = [
            ...fields,
            'position_in_company',
            'company_size',
            'experience_with_representatives',
            'commercial_team_size',
          ]
        } else {
          fields = [
            ...fields,
            'experience_with_representation',
            'constituted_company',
            'core',
            'quantity_represented',
            'portfolio',
          ]
        }

        const data = {
          origin: '',
        }
        if (this.sidebarRole === 'sales') {
          data.origin = 'sales'
        }
        if (['relationship', 'success'].includes(this.sidebarRole)) {
          data.origin = 'success'
        }

        fields.forEach(field => {
          data[field] = this.results[field] ?? ''
        })

        if (data.crm === 0) {
          data.crm_detail = ''
        }

        if (this.userType === 'company') {
          return this.userService.updateDemographicCompany(this.userId, data)
        }

        return this.userService.updateDemographicRepresentative(this.userId, data)
      })
    },

    updateDemographic() {
      this.postDemographic().then(() => {
        this.toastSuccess('Alteração salva!')
      }).catch(error => {
        this.toastDanger('Ops - Existem erros de validação', Object.values(error.response.data.errors))
      }).finally(() => {
        this.isSubmiting = false
      })
    },

    sortingChanged(ctx) {
      if (ctx.sortBy !== '') {
        this.filtersValues.sortBy = ctx.sortBy
        this.filtersValues.sortDesc = ctx.sortDesc
      }
    },
  },
}
</script>

<style scoped>
h4 {
    margin-bottom: 20px;
}

.no-padding .card-body {
    padding: 0px !important;
}
</style>
