<template xmlns:b-row="http://www.w3.org/1999/html">
  <b-card-actions
    ref="cardActions"
    title="Favoritos"
    :action-collapse="false"
    :action-close="false"
    :action-refresh="true"
    @refresh="getFavorites"
  >
    <b-row>
      <b-col cols="12">
        <b-table
          :busy="isLoading"
          responsive
          :fields="result_fields"
          :items="results"
          sort-icon-left
          no-local-sorting
          :sort-by.sync="filtersValues.sortBy"
          :sort-desc.sync="filtersValues.sortDesc"
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center table-spinner">
              <b-spinner label="Loading..." />
              <strong>Carregando...</strong>
            </div>
          </template>

          <template #head(user_id)>
            Usuário Id
          </template>

          <template #head(name)>
            Nome
          </template>

          <template #head(actions)>
            <div class="text-center">
              <span>Ações</span>
            </div>
          </template>

          <template #head(created_at)>
            Favoritado em
          </template>

          <template #cell(created_at)="row">
            {{ row.item.created_at | date }}
          </template>

          <template #cell(actions)="row">
            <div class="text-nowrap text-center">
              <b-button
                v-if="$can('read', 'Favorites')"
                variant="flat-primary"
                title="Ver html"
                @click="deleteFavorite(row.item.id)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </b-button>
            </div>
          </template>

        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <!-- pagination nav -->
        <b-pagination-nav
          v-model="currentPage"
          :number-of-pages="pages.last_page"
          base-url="#"
          align="end"
        />
      </b-col>
    </b-row>

  </b-card-actions>
</template>

<script>
import UserService from '@/services/userService'
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BTable,
  // BCard,
  BPaginationNav,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BButton,
    // BCard,
    BTable,
    BSpinner,
    BPaginationNav,
  },

  props: {
    userId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      isSubmiting: false,
      isLoading: true,
      userService: null,
      initExecuted: false,
      settings: '',
      pages: {
        last_page: 1,
      },
      currentPage: 1,
      results: [],
      result_fields: [
        { key: 'id', sortable: true },
        { key: 'user_id', sortable: true },
        { key: 'name', sortable: true },
        { key: 'created_at', sortable: true },
      ],

      filtersValues: {
        name: '',
        status: 'all',
        payment_type: 'all',
        account_manager: 'all',
        user_id: '',
        sortBy: 'id',
        sortDesc: true,
      },
    }
  },

  watch: {
    currentPage() {
      this.getFavorites()
    },
  },

  created() {
    this.userService = new UserService()

    if (this.showActions()) {
      this.result_fields.push('actions')
    }
  },

  methods: {
    init() {
      if (!this.initExecuted) {
        this.getFavorites()
        this.initExecuted = true
      }
    },

    showActions() {
      return this.$can('delete', 'Favorites')
    },

    deleteFavorite(favoriteId) {
      this.$swal({
        title: 'Remover favorito?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, remover!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.userService.deleteFavorite(favoriteId).then(response => {
            if (response.status === 200) {
              this.results = this.results.filter(filter => filter.id !== favoriteId)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Favorito ${favoriteId} removido!`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  // text: `!`,
                },
              })
            }
          })
        }
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro!',
            text: error.response.data.message,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },

    getFavorites() {
      this.isLoading = true

      this.userService.listFavorites(this.userId, this.currentPage, this.filtersValues.sortBy, this.filtersValues.sortDesc).then(response => {
        this.results = response.data.data.favorites.data
        this.pages = { ...response.data.data.favorites, data: null }
        this.isLoading = false
        this.$refs.cardActions.showLoading = false
      })
    },

    sortingChanged(ctx) {
      if (ctx.sortBy !== '') {
        this.filtersValues.sortBy = ctx.sortBy
        this.filtersValues.sortDesc = ctx.sortDesc
        this.getFavorites()
      }
    },
  },
}
</script>

<style scoped>
  h4{
    margin-bottom: 20px;
  }
</style>
