import useJwt from '@/auth/jwt/useJwt'

export default class ZoneService {
  // eslint-disable-next-line class-methods-use-this
  changeEndOfPlan(quotaId, endOfPlan) {
    return useJwt.put(`/user/quota/end-of-plan/${quotaId}`, {}, {
      end_of_plan: endOfPlan,
    })
  }

  // eslint-disable-next-line class-methods-use-this
  createDailySignature(userId, type, signatureLength) {
    return useJwt.post('/user/quota/daily-signature', {}, {
      user_id: userId,
      type,
      signature_length: signatureLength,
    })
  }
}
