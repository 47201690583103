import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  methods: {
    toastSuccess(title, text) {
      this.showToast('success', title, text, 'EditIcon')
    },

    toastDanger(title, text) {
      this.showToast('danger', title, text, 'BellIcon')
    },

    toastInfo(title, text) {
      this.showToast('info', title, text, 'InfoIcon')
    },

    showToast(type, title, text, icon, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant: type,
        },
      },
      {
        position: position ?? 'top-right',
      })
    },
  },
}
