const segments = 'segments'
const zones = 'zones'
const presentationTemplates = 'presentation-templates'
const userData = 'userData'
const managers = 'managers'
const emailTemplates = 'emailTemplates'
const dashboardModalWelcomeAt = 'dashboard-modal-welcome-at'

export default class LocalStorageService {
  static get userData() {
    return userData
  }

  static get presentationTemplates() {
    return presentationTemplates
  }

  static get segments() {
    return segments
  }

  static get zones() {
    return zones
  }

  static get managers() {
    return managers
  }

  static get emailTemplates() {
    return emailTemplates
  }

  static get dashboardModalWelcomeAt() {
    return dashboardModalWelcomeAt
  }

  static getString(name) {
    return localStorage.getItem(name)
  }

  // eslint-disable-next-line class-methods-use-this
  static setString(name, data) {
    localStorage.setItem(name, data)
  }

  // eslint-disable-next-line class-methods-use-this
  static getArray(name) {
    return JSON.parse(localStorage.getItem(name))
  }

  // eslint-disable-next-line class-methods-use-this
  static setArray(name, data) {
    localStorage.setItem(name, JSON.stringify(data))
  }

  // eslint-disable-next-line class-methods-use-this
  static remove(name) {
    localStorage.removeItem(name)
  }
}
