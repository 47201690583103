import { render, staticRenderFns } from "./UserListQuotas.vue?vue&type=template&id=2309844e&scoped=true&xmlns%3Ab-row=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./UserListQuotas.vue?vue&type=script&lang=js&"
export * from "./UserListQuotas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2309844e",
  null
  
)

export default component.exports