<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>

          <b-card
            border-variant="secondary"
          >
            <b-row>
              <b-col
                cols="1"
                class="pl-0 pr-0 text-center"
              >
                <b-avatar
                  v-if="user && user.avatar && user.avatar.sm"
                  size="35px"
                  :src="user.avatar.sm"
                />
                <b-avatar
                  v-else
                  size="35px"
                  variant="light-danger"
                  :text="getInitials(user.name)"
                />
              </b-col>

              <b-col cols="11">
                <span>Nome: {{ user.name }}</span><br>
                <span>Status: {{ $t(`users.columns.status.${user.status}`) }}</span><br>
                <span>Final do Plano: {{ user.end_of_plan | date('DD/MM/Y') }}</span>
              </b-col>
            </b-row>
          </b-card>

          <validation-observer ref="rulesPayment">
            <b-row class="mb-1">
              <b-col cols="3">
                <b-form-group
                  label="Produto"
                  label-for="product_id"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|min:1"
                  >
                    <b-form-select
                      id="product_id"
                      v-model="payment.product_id"
                      :options="productsOptions"
                      :state="errors.length > 0 ? false:null"
                      :disabled="isLoading || isSubmiting || isCreatingPayment || phase > 1"
                      @change="validatePlan"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="3">
                <b-form-group
                  label="Método de pagamento"
                  label-for="method"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|min:1"
                  >
                    <b-form-select
                      id="payment_method"
                      v-model="payment.method"
                      :options="method"
                      :state="errors.length > 0 ? false:null"
                      :disabled="isLoading || isSubmiting || isCreatingPayment || phase > 1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="3">
                <b-form-group
                  label="Desconto (em %)"
                  label-for="percent"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules=""
                  >
                    <b-form-input
                      id="payment_percent"
                      v-model="percent"
                      :disabled="isLoading || isSubmiting || isCreatingPayment || phase > 1"
                      @focus="typeDiscountField = 'percent'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="3">
                <b-form-group
                  label="Desconto (em R$)"
                  label-for="discount"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <money
                      v-model="discount"
                      class="form-control"
                      v-bind="{ decimal: ',', thousands: '.', prefix: 'R$ ', precision: 2, masked: false }"
                      :disabled="isLoading || isSubmiting || isCreatingPayment || phase > 1"
                      @focus.native="typeDiscountField = 'discount'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <label>Total</label>
                <div>R$ {{ totalPrice }}</div>
                <span
                  v-if="showProportionalMessage !== ''"
                  class="text-danger"
                >{{ showProportionalMessage }}</span>
              </b-col>

              <b-col
                v-if="payment.method === 'boleto'"
                cols="3"
              >
                <b-form-group
                  label="Vencimento"
                  label-for="expires_at"
                >
                  <flat-pickr
                    id="payment_expires_at"
                    ref="datePicker"
                    v-model="payment.expires_at"
                    class="form-control"
                    :disabled="isLoading || isSubmiting || isCreatingPayment || phase > 1"
                    :config="{
                      allowInput: false,
                      position: 'auto center',
                      enableTime: false,
                      dateFormat: 'Y-m-d',
                      altFormat: 'd/m/Y',
                      altInput: true,
                      locale: Portuguese,
                    }"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>

          <b-row
            v-if="phase >= 2"
            class="mt-4"
          >
            <b-col cols="12">
              <h3>Link gerado</h3>
            </b-col>
            <b-col cols="12">
              <div class="details">
                <label>Link: </label>
                <a
                  :href="payment.link"
                  target="_blank"
                >{{ payment.link }}</a>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12 mb-1">
              <b-alert
                v-model="showError"
                v-height-fade.appear
                class="mb-0"
                variant="primary"
              >
                <div class="alert-body">
                  <span>Somente usuários ativos com assinatura ativa podem contratar <strong>Turbine seu Perfil</strong></span>
                </div>
              </b-alert>
            </b-col>

            <b-col
              cols="12"
              class="text-right"
            >
              <div
                v-if="isLoading"
                class="mr-1 d-inline-block"
              >
                <b-spinner small />
                <span class="ml-1">Carregando...</span>
              </div>

              <b-button
                v-if="phase == 1"
                align="right"
                variant="primary"
                type="submit"
                :disabled="isLoading || isCreatingPayment || showError"
                @click.prevent="createPayment()"
              >
                <span>Criar link de pagamento</span>
                <b-spinner
                  v-if="isCreatingPayment"
                  class="ml-1"
                  small
                />
              </b-button>

              <b-button
                v-if="phase == 2"
                align="right"
                variant="primary"
                type="submit"
                @click="close()"
              >
                <span>Fechar</span>
              </b-button>
            </b-col>
          </b-row>

        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
// import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getInitials } from '@/utils/helpers'
import ProductService from '@/services/productService'
import PlanService from '@/services/planService'
import UserService from '@/services/userService'
import { Money } from 'v-money'
import { heightFade } from '@core/directives/animations'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BAlert,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BSpinner,
  BButton,
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BSpinner,
    BButton,
    Money,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },

  directives: {
    'height-fade': heightFade,
  },

  props: {
    userId: {
      type: Number,
      required: true,
    },
    filterByUser: {
      type: Number,
      default: null,
    },
    filterById: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      getInitials,
      Portuguese,
      isLoading: true,
      isSubmiting: false,
      isCreatingPayment: false,
      phase: 1,
      percent: '',
      discount: '',
      typeDiscountField: '',
      showError: false,
      showProportionalMessage: '',
      user: {
        name: '',
        avatar: null,
        end_of_plan: null,
      },
      payment: {
        product_id: null,
        plan_id: null,
        turbo: null,
        link: '',
        email: '',
        percent: 0,
        discount: 0,
        transaction_id: null,
        expires_at: null,
      },
      method: [
        { value: 'credit_card', text: 'Cartão de Crédito' },
        { value: 'boleto', text: 'Boleto' },
        { value: 'pix', text: 'Pix' },
      ],
      plans: [],
      products: [],
      productSuper: null,
      turbo: [
        { value: 1, text: 'Sim' },
        { value: 0, text: 'Não' },
      ],
      planService: null,
      productService: null,
      userService: null,
    }
  },

  computed: {
    productsOptions() {
      return this.products.map(m => ({
        value: m.id,
        text: m.name,
      }))
    },

    productPrice() {
      const product = this.products.find(f => f.id === this.payment.product_id)

      if (product) {
        return product.price
      }

      return 0
    },

    totalPrice() {
      const productPrice = this.calcProductPrice()
      return parseFloat(productPrice - this.payment.discount).toFixed(2)
    },

    periods() {
      const plan = this.plans.filter(f => (
        f.id === this.payment.group_id
      ))

      if (plan.length > 0) {
        return plan[0].plans.map(m => ({
          value: m.id,
          text: m.payment_interval_label,
        }))
      }

      return []
    },
  },

  watch: {
    discount(event) {
      if (this.typeDiscountField === 'discount') {
        this.payment.discount = event
        this.percent = ''
        this.payment.percent = this.percent
      }
    },

    percent(event) {
      if (this.typeDiscountField === 'percent') {
        this.payment.percent = event
        this.discount = parseFloat(parseFloat(this.productPrice * (event / 100)).toFixed(2))
        this.payment.discount = this.discount
      }
    },
  },

  async created() {
    this.planService = new PlanService()
    this.userService = new UserService()
    this.productService = new ProductService()

    this.payment.expires_at = (new Date((new Date()).setDate((new Date()).getDate() + 3)).toISOString().substr(0, 10))

    this.isLoading = true
    await this.getUser()
    await this.getProducts()

    this.payment.email = this.user.email
    this.isLoading = false
  },

  methods: {
    calcProductPrice() {
      const product = this.products.find(f => f.id === this.payment.product_id)

      if (product && product.can_attach_to_the_plan) {
        const endOfPlan = new Date(this.user.end_of_plan)
        const now = new Date()
        const diffTime = Math.abs(endOfPlan - now)
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
        this.showProportionalMessage = `Cobrança proporcional até o próximo ciclo de pagamento. ${diffDays} dias`
        return this.productPrice * (diffDays / 30)
      }

      this.showProportionalMessage = ''
      return this.productPrice
    },

    close() {
      this.$emit('close')
    },

    validatePlan() {
      const now = new Date()
      const endOfPlan = new Date(this.user.end_of_plan)
      if (this.payment.product_id === 1 && now.getTime() > endOfPlan.getTime()) {
        this.showError = true
      } else {
        this.showError = false
      }
    },

    getUser() {
      return this.userService.get(this.userId).then(response => {
        this.user = response.data.data.user
      })
    },

    getProducts() {
      this.productService.list().then(response => {
        this.products = response.data.data.products
        this.productSuper = this.products.find(f => f.type === 'weight_in_search_ordering')
      })
    },

    sendEmail() {
      this.isSubmiting = true
      this.userService.sendChagerFromTransaction(this.payment.transaction_id, this.payment.email).then(response => {
        this.isSubmiting = false
        if (response.data.success) {
          this.$emit('email-sent')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'E-mail Enviado',
              text: 'E-mail com cobrança enviado com sucesso!',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        } else {
          this.isSubmiting = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erros de validação',
              text: response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(() => {
        this.isSubmiting = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erros de validação',
            text: 'Algum erro ocorreu',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },

    createPayment() {
      return this.$refs.rulesPayment.validate().then(success => {
        if (success) {
          this.isCreatingPayment = true
          this.userService.createPayment(
            this.userId,
            this.payment.plan_id,
            this.payment.method,
            this.payment.discount,
            this.payment.turbo,
            this.payment.expires_at,
            this.payment.product_id,
          ).then(response => {
            if (response.data.success) {
              this.payment.link = response.data.url
              this.payment.transaction_id = response.data.transaction_id
              this.phase = 2
              this.$emit('payment-created', {})
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erros de validação',
                  text: response.data.message,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            }

            this.isCreatingPayment = false
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Erros de validação',
                text: 'Algum erro ocorreu',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erros de validação',
              text: 'Preencha todos os campos obrigatórios',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .header-card{
    margin-bottom: 20px;
  }

  .label-date{
    display: block;
    margin-top: 30px;
    font-weight: bold;
  }

  .form-buttons button{
    margin-right: 10px;
  }

  .details{
    label{
      margin-right: 10px;
    }

    label, span{
      font-size: 14px;
    }
  }
</style>
