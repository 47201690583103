import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  methods: {
    sendToClipboard(text) {
      const el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Texto copiado!',
          text: '',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
  },
}
