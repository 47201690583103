export default [
  {
    path: '/cms/commercia/users',
    name: 'cms-commercia-users',
    component: () => import('@/views/cms/commercia/user/List.vue'),
    meta: {
      resource: 'Commercia',
      action: 'read',
    },
  },
  {
    path: '/cms/commercia/plans',
    name: 'cms-commercia-plans',
    component: () => import('@/views/cms/commercia/plan/List.vue'),
    meta: {
      resource: 'Commercia',
      action: 'read',
    },
  },
  {
    path: '/cms/commercia/form/:id',
    name: 'cms-commercia-plan-form',
    component: () => import('@/views/cms/commercia/plan/Form.vue'),
    meta: {
      resource: 'Commercia',
      action: 'read',
    },
  },
  {
    path: '/cms/commercia/plan-additionals',
    name: 'cms-commercia-plan-additionals',
    component: () => import('@/views/cms/commercia/plan-additional/List.vue'),
    meta: {
      resource: 'Commercia',
      action: 'read',
    },
  },
  {
    path: '/cms/commercia/plan-additionals/form/:id',
    name: 'cms-commercia-plan-additional-form',
    component: () => import('@/views/cms/commercia/plan-additional/Form.vue'),
    meta: {
      resource: 'Commercia',
      action: 'read',
    },
  },
]
