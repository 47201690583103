/* eslint-disable class-methods-use-this, import/no-extraneous-dependencies */
import useJwt from '@/auth/jwt/useJwt'

export default class SchedulerService {
  static postSendLinkToSchedule(managerId, userId, type) {
    return useJwt.post('/scheduler/send-invite-to-schedule', {}, {
      manager_id: managerId,
      user_id: userId,
      type,
    })
  }

  static getHash(createdAt) {
    const base64 = btoa(createdAt)
    const base64Clean = base64.replace(/=/g, '')
    return base64Clean.replace(/=/g, '').substring(base64Clean.length - 4, base64Clean.length)
  }

  static getEventByManager(managerId, event, days) {
    const daysForward = days ?? 30
    return useJwt.get(`/scheduler/event/${managerId}/${event}?days=${daysForward}`)
  }

  static getConsultants() {
    return useJwt.get('/scheduler/redirect-request/consultants')
  }

  static postConsultant(consultant) {
    return useJwt.post('/scheduler/redirect-request/consultant', {}, consultant)
  }

  static deleteConsultant(consultantId) {
    return useJwt.delete('/scheduler/redirect-request/consultant', { id: consultantId })
  }

  static getConsultant(consultantId) {
    return useJwt.get('/scheduler/redirect-request/consultant', { id: consultantId })
  }

  static getDisabledDates() {
    return useJwt.get('/scheduler/redirect-request/disabled-dates')
  }

  static getDisabledDatesRelations() {
    return useJwt.get('/scheduler/redirect-request/disabled-dates-relations')
  }

  static postDisabledDate(data) {
    return useJwt.post('/scheduler/redirect-request/disabled-date', null, data)
  }

  static deleteDisabledDate(disabledDateId) {
    return useJwt.delete('/scheduler/redirect-request/disabled-date', { id: disabledDateId })
  }

  static postSchedule(managerId, listenerId, notes, startAt, interval) {
    return useJwt.get('/host/schedule', {
      manager_id: managerId,
      listener_id: listenerId,
      start_at: startAt,
      notes,
      interval,
    })
  }
}
