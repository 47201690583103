<template>
  <b-form-group
    label="CEP"
    label-for="registration_info_zip_code"
  >
    <validation-observer ref="rulesRegistration">
      <validation-provider
        #default="{ errors }"
        rules="required|min:8"
      >
        <b-input-group>
          <cleave
            id="registration_info_zip_code"
            :value="zipCode"
            class="form-control"
            placeholder="CEP"
            :options="maskCep"
            :raw="false"
            :disabled="isSubmiting"
            @input="$emit('update:zip-code', $event)"
          />

          <b-input-group-append>
            <b-button
              variant="outline-primary"
              :disabled="isSubmiting"
              @click="getAddressByCep(zipCode)"
            >
              <b-spinner
                v-if="isLoading"
                small
              />
              <feather-icon
                v-if="!isLoading"
                animation="spin-pulse"
                icon="SearchIcon"
              />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </validation-observer>
  </b-form-group>
</template>

<script>
import { maskCep } from '@/utils/masks'
import Cleave from 'vue-cleave-component'
import {
  BFormGroup,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from 'bootstrap-vue'
import UserService from '@/services/userService'
import ZoneService from '@/services/zoneService'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ZipCodeInput',
  components: {
    BSpinner,
    BButton,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    Cleave,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [],
  props: {
    isSubmiting: {
      type: Boolean,
      default: false,
    },
    zipCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      userService: null,
      zoneService: null,
      maskCep,
    }
  },
  created() {
    this.userService = new UserService()
    this.zoneService = new ZoneService()
  },
  methods: {
    getAddressByCep(cep) {
      this.isLoading = true
      this.zoneService.apiCep(cep).then(response => {
        if (response.status === 200) {
          this.$emit('response', response)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops!',
              text: 'Cep não encontrado',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }

        this.isLoading = false
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ops!',
            text: 'Cep não encontrado',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
</style>
