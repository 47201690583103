/* eslint-disable class-methods-use-this, import/no-extraneous-dependencies */
import useJwt from '@/auth/jwt/useJwt'

export default class ConnectionService {
  getMessages(userId1, userId2, type, page = 0, order = '', desc = false) {
    const direction = desc ? ' DESC' : ''
    if (userId1 !== '' && ['enviadas', 'recebidas'].includes(type)) {
      return useJwt.get(`/messages/${type}/${userId1}?page=${page}&order_by=${order} ${direction}`)
    }

    if (userId1 !== '' && userId2 !== '' && type === 'conversa') {
      return useJwt.get(`/messages/${type}/${userId1}/${userId2}?page=${page}&order_by=${order} ${direction}`)
    }

    return useJwt.get(`/messages?page=${page}`)
  }

  unreadOpenConversations(userId, data) {
    return useJwt.post(`/conversation/unread-open/${userId}`, {}, data)
  }

  migrate(userIdFrom, userIdTo) {
    return useJwt.post(`/conversation/migrate/${userIdFrom}/${userIdTo}`)
  }

  validateMigrate(userIdFrom, userIdTo) {
    return useJwt.get(`/conversation/validate-migrate/${userIdFrom}/${userIdTo}`)
  }

  deleteMessage(messageId) {
    return useJwt.delete(`/message/${messageId}`)
  }

  closeMessage(conversationId, reason) {
    return useJwt.patch(`/conversation/finish/${conversationId}/${reason}`)
  }

  getMessage(messageId) {
    return useJwt.get(`/message/${messageId}`)
  }

  getDirectLink(conversationId) {
    return useJwt.get(`/conversation/direct-link/${conversationId}`)
  }

  sendMessageLink(conversationId, data) {
    return useJwt.post(`/conversation/send-message-link/${conversationId}`, {}, data)
  }

  interestReinforcement(data) {
    return useJwt.post('/conversation/interest-reinforcement', {}, data)
  }

  login() {
    return 'url'
  }
}
