<template xmlns:b-row="http://www.w3.org/1999/html">
  <b-card-actions
    ref="cardActions"
    title="Planos"
    class="form-filters"
    :action-collapse="false"
    :action-close="false"
    :action-refresh="true"
    @refresh="getQuotas"
  >
    <b-row
      class="header-card"
      align-v="center"
    >
      <b-col
        cols="12"
        class="text-right"
      >
        <b-button
          class="add-proposal mr-1"
          variant="primary"
          @click="openDailySignature"
        >
          <feather-icon
            v-if="!isLoading"
            icon="PlusIcon"
            size="16"
            class="align-middle"
          />
          <b-spinner
            v-else
            small
            class="align-middle"
          /> Liberação de assinatura
        </b-button>
      </b-col></b-row>

    <b-row>
      <b-col cols="12">
        <b-table
          :busy="isLoading"
          responsive
          :fields="result_fields"
          :items="results"
          sort-icon-left
          :sort-by.sync="filtersValues.sortBy"
          :sort-desc.sync="filtersValues.sortDesc"
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center table-spinner">
              <b-spinner label="Loading..." />
              <strong>Carregando...</strong>
            </div>
          </template>

          <template #head(created_at)>
            Criado em
          </template>

          <template #head(end_of_plan)>
            Fim do plano
          </template>

          <template #head(products)>
            Produtos Adicionais
          </template>

          <template #head(plan_name)>
            Plano
          </template>

          <template #head(manager_name)>
            Gerente de conta
          </template>

          <template #head(payment_transaction_id)>
            Transação
          </template>

          <template #head(actions)>
            <div class="text-nowrap text-center">
              Ações
            </div>
          </template>

          <template #cell(end_of_plan)="row">
            {{ row.item.end_of_plan | date('DD/MM/YYYY') }}
          </template>

          <template #cell(created_at)="row">
            {{ row.item.created_at | date() }}
          </template>

          <template #cell(payment_transaction_id)="row">
            <a
              class="text-primary"
              @click="openTransaction(row)"
            >{{ row.item.payment_transaction_id }}</a>
          </template>

          <template #cell(actions)="row">
            <div class="text-nowrap text-center">
              <b-button
                v-if="$can('update', 'Quota')"
                variant="flat-primary"
                title="Ver html"
                @click="changeEndOfPlan(row)"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- modal -->
    <b-modal
      id="modal-daily-signature"
      ref="modal-daily-signature"
      hide-footer
      size="md"
      title="Liberação de assinatura"
    >
      <div>
        <b-alert
          v-if="results.some(q => q.status === 'ativo')"
          variant="danger"
          class="px-2 py-1"
          show
        >
          <strong>Atenção!</strong><br>
          Você está prestes a liberar assinatura para um usuário com plano ativo.
        </b-alert>
        <validation-observer ref="rulesPayment">
          <b-row class="mb-1">
            <b-col cols="12">
              <p>
                Selecione o tipo da assinatura para adicionar ao usuário
              </p>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Plano"
                label-for="signature_type"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required|min:1"
                >
                  <b-form-select
                    v-model="selectedSignatureType"
                    :options="signatureTypes"
                    :state="errors.length > 0 ? false:null"
                    :disabled="isLoading"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Liberação"
                label-for="signature_length"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required|min:1"
                >
                  <b-form-select
                    v-model="selectedSignatureLength"
                    :options="signatureLengths"
                    :state="errors.length > 0 ? false:null"
                    :disabled="isLoading"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                align="right"
                variant="primary"
                type="submit"
                :disabled="isLoadingDailySignature"
                @click="createDailySignature"
              >
                <b-spinner
                  v-if="isLoadingDailySignature"
                  small
                  class="align-middle"
                />
                Criar plano
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </div>
    </b-modal>

    <b-modal
      id="modal-change-end-of-plan"
      cancel-variant="outline-secondary"
      ok-title="Alterar"
      cancel-title="Close"
      centered
      title="Alterar data de final do plano"
      class
      @ok="saveEndOfPlan"
    >
      <b-form>
        <b-form-group>
          <label for="end_of_plan">Data final do plano:</label>

          <b-input-group>
            <cleave
              id="end_of_plan"
              v-model="quota.end_of_plan"
              type="text"
              class="form-control"
              placeholder="DD/MM/YYYY"
              autocomplete="off"
              :raw="false"
              show-decade-nav
              :options="maskDate"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="end_of_plan_dp"
                show-decade-nav
                button-only
                button-variant="outline-primary"
                right
                size="sm"
                :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                locale="pt-BR"
                aria-controls="example-input"
                @context="(ctx) => { quota.end_of_plan = (ctx.selectedDate) ? ctx.selectedFormatted : '' }"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-form>
    </b-modal>

  </b-card-actions>
</template>

<script>
import Cleave from 'vue-cleave-component'
import UserService from '@/services/userService'
import QuotaService from '@/services/quotaService'
import { maskDate } from '@/utils/masks'
import {
  BAlert,
  BForm,
  BFormGroup,
  BInputGroup,
  BRow,
  BCol,
  BInputGroupAppend,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormSelect,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '../../../@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAlert,
    BForm,
    Cleave,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BTable,
    BSpinner,
    BCardActions,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    userId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      isLoading: true,
      isLoadingDailySignature: false,
      userService: null,
      quotaService: null,
      initExecuted: false,
      end_of_plan_dp: '',
      filtersInitialValues: {},
      filtersValues: {
        name: '',
        status: 'all',
        payment_type: 'all',
        account_manager: 'all',
        user_id: '',
        sortBy: 'id',
        sortDesc: true,
      },
      signatureTypes: [
        {
          text: 'Pacote de serviços',
          value: 'service',
        },
        {
          text: 'Pacote de serviços - GOLD',
          value: 'gold',
        },
      ],
      selectedSignatureType: '',
      signatureLengths: [
        { value: 0, text: 'Liberação diária' },
        { value: 1, text: 'Liberação diária + 1 dia' },
        { value: 2, text: 'Liberação diária + 2 dias' },
        { value: 3, text: 'Liberação diária + 3 dias' },

      ],
      selectedSignatureLength: 0,
      quota: {
        id: null,
        endOfPlan: null,
      },
      newEndOfPlan: 0,
      pages: 1,
      currentPage: 1,
      results: [],
      result_fields: [
        { key: 'status', sortable: true },
        { key: 'created_at', sortable: true },
        { key: 'end_of_plan', sortable: true },
        { key: 'plan_name', sortable: true },
        { key: 'products', sortable: true },
        { key: 'manager_name', sortable: true },
        { key: 'payment_transaction_id', sortable: true },
      ],
      maskDate,
    }
  },

  created() {
    this.userService = new UserService()
    this.quotaService = new QuotaService()

    if (this.showActions()) {
      this.result_fields.push({ key: 'actions', sortable: false })
    }
  },

  methods: {
    init() {
      if (!this.initExecuted) {
        this.getQuotas()
        this.initExecuted = true
      }
    },

    transformDate(date) {
      if (date.length === 10) {
        const from = date.split('/')
        return `${from[2]}-${from[1]}-${from[0]}`
      }
      return ''
    },

    changeEndOfPlan(row) {
      this.quota = {
        id: row.item.id,
        end_of_plan: row.item.end_of_plan,
      }
      this.end_of_plan_dp = row.item.end_of_plan
      this.$bvModal.show('modal-change-end-of-plan')
    },

    createDailySignature() {
      this.isLoadingDailySignature = true
      this.quotaService.createDailySignature(this.userId, this.selectedSignatureType, this.selectedSignatureLength).then(response => {
        this.isLoadingDailySignature = false

        if (response.status === 200) {
          this.$bvModal.hide('modal-daily-signature')
          this.getQuotas()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Plano diário criado com sucesso!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              text: 'Não foi possível criar o plano diário',
            },
          })
        }
      })
    },

    openDailySignature() {
      this.$bvModal.show('modal-daily-signature')
    },

    saveEndOfPlan() {
      this.isLoading = true
      this.quotaService.changeEndOfPlan(this.quota.id, this.transformDate(this.quota.end_of_plan)).then(response => {
        if (response.status === 200) {
          this.getQuotas()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Data do plano alterada!',
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `!`,
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              text: 'Não foi possível alterar a data final do plano',
            },
          })
        }
      })
    },

    showActions() {
      return this.$can('update', 'Quota')
    },

    openTransaction(row) {
      this.$emit('open-transaction', row.item.payment_transaction_id)
    },

    getQuotas() {
      this.userService.listQuotas(this.userId).then(response => {
        this.results = response.data.data.quotas
        const today = new Date(`${(new Date()).toISOString().substr(0, 10)} 00:00:00`)
        if (this.results && this.results.length) {
          this.results = this.results.map(quota => ({
            ...quota,
            status: new Date(quota.end_of_plan) >= today ? 'ativo' : 'vencido',
            manager_name: quota.manager?.name,
            plan_name: `${quota.plan?.group?.name} - ${quota.plan?.payment_interval_label ?? ''}`,
            products: quota.products ? quota.products.map(product => (product.name)).join(',') : [],
          }))
        }
        this.isLoading = false
        this.$refs.cardActions.showLoading = false
      })
    },

    sortingChanged(ctx) {
      if (ctx.sortBy !== '') {
        this.filtersValues.sortBy = ctx.sortBy
        this.filtersValues.sortDesc = ctx.sortDesc
      }
    },
  },
}
</script>

<style scoped>

</style>
