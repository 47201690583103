import useJwt from '@/auth/jwt/useJwt'
import LocalStorageService from '@/services/localStorageService'

export default class SegmentService {
  // eslint-disable-next-line class-methods-use-this
  list() {
    return useJwt.get('/segments')
  }

  // eslint-disable-next-line class-methods-use-this
  getAndStoreSegments() {
    if (LocalStorageService.getArray(LocalStorageService.segments) !== null) {
      return new Promise(resolve => {
        resolve(LocalStorageService.getArray(LocalStorageService.segments))
      })
    }

    return this.list().then(response => {
      if (response.data !== null) {
        let { segments } = response.data.data
        segments = Object.values(segments)

        LocalStorageService.setArray(LocalStorageService.segments, segments)

        return segments
      }

      return []
    })
  }
}
