import moment from 'moment'
import {
  knownDomainsOptions,
} from '@/utils/options'

export function formatDateViewToDb(value) {
  if (value) {
    return moment(String(value), 'DD/MM/YYYY').format('YYYY-MM-DD')
  }

  return ''
}

export function formatDateTimeViewToDb(value) {
  if (value) {
    return moment(String(value), 'DD/MM/YYYY hh:mm:ss').format('YYYY-MM-DD hh:mm:ii')
  }

  return ''
}

export function formatDateDbToView(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }

  return ''
}

export function formatDateTimeDbToView(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
  }

  return ''
}

export function getInitials(name) {
  if (name === undefined) {
    return ''
  }

  const s = name.split(' ')
  if (s.length === 1) {
    return s[0].substring(0, 1).toUpperCase()
  }

  return `${s[0].substring(0, 1).toUpperCase()}${s[1].substring(0, 1).toUpperCase()}`
}

export function formatCamelCase(value) {
  return value.toLowerCase().replace(/(\s(.)|^(.))/g, c => c.toUpperCase())
}

export function formatLocalizeNumber(number) {
  return number.toLocaleString('pt-BR')
}

export function removeKnownDomains(email) {
  const blackList = knownDomainsOptions.map(m => m.value)

  const emailMatch = email.match(/(.*)@(.*)/)
  if (emailMatch) {
    const domain = emailMatch[2]
    const address = emailMatch[1]

    if (blackList.find(f => f === domain || `${f}.br` === domain)) {
      return address
    }
    return email.replace('.com', '').replace('.br', '').replace('.net', '')
  }

  return email
}

export const nl2br = s => s.split('\n').join('<br>')
