export default {
  methods: {
    getResponseErrorMessage(response) {
      if (response.errors && response.errors.length === 2) {
        return `${response.errors[0]}: ${response.errors[1]}`
      }

      if (response.errors && typeof response.errors === 'object' && Object.entries(response.errors).length > 0) {
        let ret = ''
        Object.entries(response.errors).forEach(value => {
          ret = `${ret}\n${value[0]}: ${value[1][0]}`
        })
        return ret
      }

      if (response.response && response.response.data && response.response.data.errors && typeof response.response.data.errors === 'object') {
        let ret = ''
        Object.entries(response.response.data.errors).forEach(value => {
          ret = `${ret}\n${value[0]}: ${value[1][0]}`
        })
        return ret
      }

      return 'Erro'
    },
  },
}
