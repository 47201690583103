import useJwt from '@/auth/jwt/useJwt'

export default class FinancialService {
  // eslint-disable-next-line class-methods-use-this
  list(filters, page = 0) {
    let params = {}
    if (filters !== undefined) {
      params = Object.fromEntries(Object.entries(filters).filter(filter => filter[1] !== '' && filter[1] !== 'all'))
    }

    params.order_by = params.sortBy + (params.sortDesc ? ' DESC' : '')
    delete params.sortDesc
    delete params.sortBy

    return useJwt.get(`/financial?page=${page}`, params)
  }

  // eslint-disable-next-line class-methods-use-this
  successClients(filters, page = 0) {
    let params = {}
    if (filters !== undefined) {
      params = Object.fromEntries(Object.entries(filters).filter(filter => filter[1] !== '' && filter[1] !== 'all'))
    }

    params.order_by = params.sortBy + (params.sortDesc ? ' DESC' : '')
    delete params.sortDesc
    delete params.sortBy

    return useJwt.get(`/financial/success-clients?page=${page}`, params)
  }

  // eslint-disable-next-line class-methods-use-this
  changeManager(transactionId, managerId) {
    return useJwt.post(`/financial/payment/change-manager/${transactionId}`, {}, {
      manager_id: managerId,
    })
  }

  // eslint-disable-next-line class-methods-use-this
  approvePayment(id) {
    return useJwt.put(`/financial/payment/approve/${id}`)
  }

  // eslint-disable-next-line class-methods-use-this
  sendProposal(userId) {
    return useJwt.post(`/financial/send-proposal/${userId}`)
  }

  // eslint-disable-next-line class-methods-use-this
  cancelSubscription(userId) {
    return useJwt.post(`/financial/subscription/cancel/${userId}`)
  }

  // eslint-disable-next-line class-methods-use-this
  checkSubscription(userId) {
    return useJwt.get(`/financial/subscription/check/${userId}`)
  }

  // eslint-disable-next-line class-methods-use-this
  get(id) {
    return useJwt.get(`/financial/${id}`)
  }
}
