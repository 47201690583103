<template>
  <div>
    <b-card>
      <div
        v-if="isLoading"
        class="text-center"
      >
        <b-spinner />
        <p>Carregando...</p>
      </div>

      <b-form v-if="!isLoading">
        <validation-observer ref="rulesPrimary">
          <h4>Informações Fiscais</h4>
          <b-row>
            <b-col
              cols="5"
            >
              <b-form-group
                label="Nome"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-input
                    id="name"
                    v-model="taxData.name"
                    :disabled="isSubmiting"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="5"
            >
              <b-form-group
                label="CPF"
                label-for="cpf"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <cleave
                    id="cpf"
                    v-model="taxData.cpf"
                    :disabled="isSubmiting"
                    class="form-control"
                    placeholder="000.000.000-00"
                    :options="maskCpf"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="5"
            >
              <b-form-group
                label="Empresa"
                label-for="company"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-input
                    id="company"
                    v-model="taxData.company"
                    :disabled="isSubmiting"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="5"
            >
              <b-form-group
                label="CNPJ"
                label-for="cnpj"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <cleave
                    id="cnpj"
                    v-model="taxData.cnpj"
                    :disabled="isSubmiting"
                    class="form-control"
                    placeholder="00.000.000/0000-00"
                    :options="maskCnpj"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="5"
            >
              <b-form-group
                label="E-mail"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-input
                    id="email"
                    v-model="taxData.email"
                    :disabled="isSubmiting"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="5"
            >
              <b-form-group
                label="Telefone/Celular"
                label-for="cellphone"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <cleave
                    id="cellphone"
                    v-model="taxData.cell_phone"
                    class="form-control"
                    placeholder="DDD + Número"
                    :options="taxData.cell_phone.match(/[0-9]*/g).join('').length == 10 ? maskPhone8 : maskPhone9"
                    :disabled="isSubmiting"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="4"
            >
              <zip-code-input
                :zip-code.sync="taxData.zip_code"
                :is-submiting="isSubmiting"
                @response="zipCodeResponse"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="4"
            >
              <b-form-group
                label="Estado"
                label-for="state"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required|min:2"
                >
                  <b-form-select
                    id="state"
                    v-model="taxData.state"
                    :options="userStates"
                    :state="errors.length > 0 ? false:null"
                    :disabled="isSubmiting || isLoading"
                    @change="selectState()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
            >
              <b-form-group
                label="Cidade"
                label-for="registration_info_city"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="min:3"
                >
                  <v-select
                    id="city"
                    label="title"
                    taggable
                    push-tags
                    :value="taxData.city"
                    :options="cityOptions"
                    :disabled="isSubmiting || isLoading"
                    @input="selectCity"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
            >
              <b-form-group
                label="Bairro"
                label-for="neighbor"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-input
                    id="neighbor"
                    v-model="taxData.neighbor"
                    :disabled="isSubmiting"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="4"
            >
              <b-form-group
                label="Rua"
                label-for="street"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-input
                    id="street"
                    v-model="taxData.street"
                    :disabled="isSubmiting"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
            >
              <b-form-group
                label="Número"
                label-for="number"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-input
                    id="number"
                    v-model="taxData.number"
                    :disabled="isSubmiting"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
            >
              <b-form-group
                label="Complemento"
                label-for="complement"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-input
                    id="complement"
                    v-model="taxData.complement"
                    :disabled="isSubmiting"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="10"
              class="text-left"
            >
              <b-button
                variant="primary"
                @click="copyData"
              >
                Copiar dados do Cadastro
                <b-spinner
                  v-if="isSubmiting"
                  small
                />
              </b-button>

              <b-button
                variant="primary"
                class="ml-2"
                @click="copyDataToNf"
              >
                Copiar dados para o E-notas
              </b-button>
            </b-col>

            <b-col
              cols="2"
              class="text-right"
            >
              <b-button
                :disabled="isSubmiting"
                variant="primary"
                @click="submit"
              >
                Salvar
                <b-spinner
                  v-if="isSubmiting"
                  small
                />
              </b-button>
            </b-col>
          </b-row>

        </validation-observer>

      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  maskDate, maskCep, maskPhone8, maskPhone9, maskCpf, maskCnpj,
} from '@/utils/masks'
import Cleave from 'vue-cleave-component'
import UserService from '@/services/userService'
import ZoneService from '@/services/zoneService'
import sendToClipboard from '@/mixins/sendToClipboard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { userStates } from '@/utils/options'
import vSelect from 'vue-select'
import {
  BRow,
  BButton,
  BCol,
  BSpinner,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
} from 'bootstrap-vue'
import ZipCodeInput from './inputs/ZipCodeInput.vue'

export default {
  components: {
    BSpinner,
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormSelect,
    BFormInput,
    BFormGroup,
    ZipCodeInput,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  mixins: [sendToClipboard],

  props: {
    userId: {
      type: Number,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      isSubmiting: false,
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Financeiro',
          active: true,
        },
      ],
      taxData: {},
      maskDate,
      maskCep,
      userStates,
      maskPhone8,
      maskPhone9,
      maskCpf,
      maskCnpj,
    }
  },

  computed: {
    cityOptions() {
      const state = this.zones.find(f => f.initials === this.taxData.state)
      let cities = []
      if (state !== undefined && state.zones.length === 1) {
        cities = state.zones[0].cities
      } else if (state !== undefined && state.zones.length > 1) {
        cities = state.zones.reduce((accumulator, row, index) => {
          if (index === 1) {
            return accumulator.cities.concat(row.cities)
          }
          return accumulator.concat(row.cities)
        }).map(m => m.name)
      }

      return cities
    },
  },

  async created() {
    this.zoneService = new ZoneService()

    // garante que a tela terá os dados necessários para sua execução
    this.zones = await this.zoneService.getAndStoreZones()

    this.userService = new UserService()
    this.userService.getTaxData(this.userId).then(response => {
      this.taxData = Object.fromEntries(Object.entries(response.data.data.taxData).map(m => {
        // eslint-disable-next-line no-param-reassign
        m[1] = m[1] === null || m[1] === 'null' ? '' : m[1]
        return m
      }))

      this.isLoading = false
    })
  },

  methods: {
    copyDataToNf() {
      const data = {
        doc: this.user.type === 'representative' ? this.user.cpf : this.user.cnpj,
        cell_phone: this.user.cell_phone,
        name: this.user.name,
        email: this.user.email,
        address: this.user.address,
      }

      if (this.taxData.cpf.length === 11) {
        data.doc = this.taxData.cpf
      }

      if (this.taxData.cnpj.length === 14) {
        data.doc = this.taxData.cnpj
      }

      let fields = ['name', 'email', 'cell_phone']
      fields.forEach(field => {
        if (this.taxData[field].length > 0) {
          data[field] = this.taxData[field]
        }
      })

      fields = ['state', 'city', 'neighbor', 'street', 'number', 'complement', 'zip_code']
      fields.forEach(field => {
        if (this.taxData[field].length > 0) {
          data.address[field] = this.taxData[field]
        }
      })
      this.sendToClipboard(JSON.stringify(data))
    },

    copyData() {
      let company = ''
      if (this.user.type === 'company') {
        if (this.user.company_name) {
          company = this.user.company_name
        } else if (this.user.profile.company_constituted) {
          company = this.user.profile.company_constituted
        }
      }

      this.taxData = {
        cpf: this.user.cpf,
        cnpj: this.user.cnpj,
        name: this.user.name,
        email: this.user.email,
        company,
        cell_phone: this.user.cell_phone,
        state: this.user.address.state,
        city: this.user.address.city,
        neighbor: this.user.address.neighbor,
        street: this.user.address.street,
        number: this.user.address.number,
        complement: this.user.address.complement,
        zip_code: this.user.address.zip_code,
      }
    },

    submit() {
      this.isSubmiting = true

      this.userService.updateTaxData(this.userId, this.taxData).then(() => {
        this.isSubmiting = false
      }).catch(error => {
        this.$swal({
          title: 'Ops - Existem erros de validação',
          text: Object.values(error.response.data.errors),
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

        this.isSubmiting = false
      })
    },

    zipCodeResponse(event) {
      const street = event.logradouro.split('-')[0]
      this.taxData.city = event.localidade
      this.taxData.street = street
      this.taxData.state = event.uf
      this.taxData.neighbor = event.bairro
    },
    selectState() {
      this.taxData.city = ''
      this.taxData.zip_code = ''
    },
    selectCity(value) {
      this.taxData.zip_code = ''
      this.taxData.city = value
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .header-card{
    margin-bottom: 20px;
  }

  .label-date{
    display: block;
    margin-top: 30px;
    font-weight: bold;
  }

  .form-buttons button{
    margin-right: 10px;
  }
</style>
