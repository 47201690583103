<template>
  <div class="user-edit-email">
    <b-form-group
      label="E-mails adicionais"
      label-for="email"
    >
      <validation-observer ref="simpleRules">
        <validation-provider
          v-for="(value, i) in emails.filter(f => !f.master)"
          #default="{ errors }"
          :key="i"
          rules="required|email"
          class="margin-bottom"
        >
          <b-row class="emails-row">
            <b-col cols="6">
              <b-input-group>
                <b-form-input
                  :id="'additional_email'+value.id"
                  v-model="value.email"
                  class="form-control"
                  placeholder="E-mail"
                  :disabled="isSubmiting || value.isSubmiting"
                />

                <b-input-group-append>
                  <b-button
                    v-b-tooltip.hover.noninteractive="'Definir como principal'"
                    variant="outline-info"
                    :disabled="isSubmiting || value.isSubmiting"
                    size="sm"
                    @click="setMasterEmail(value)"
                  >
                    <Iconify
                      :icon="icons.MarkerCheckIcon"
                      width="14"
                      height="14"
                    />
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    :disabled="isSubmiting || value.isSubmiting"
                    size="sm"
                    @click="remove(value)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-col>
            <b-col
              v-if="value.email !== value.old || value.id === '' || value === null"
              cols="6"
            >
              <span>Salve os dados antes de enviar o e-mail de confirmação</span>
            </b-col>
            <b-col
              v-else
              cols="6"
              class="d-flex flex-wrap align-items-center"
            >
              <span
                v-if="value.conferred_at !== null"
                class="ml-1 text-success"
              >
                <feather-icon
                  animation="spin-pulse"
                  icon="CheckIcon"
                />
                Conferido em {{ value.conferred_at | date('DD/MM/YYYY HH:mm:ss') }} por {{ userName }}
              </span>
              <span
                v-else-if="value.verified_at !== null"
                class="ml-1 text-success"
              >
                <feather-icon
                  animation="spin-pulse"
                  icon="CheckIcon"
                />
                Confirmado em {{ value.verified_at | date('DD/MM/YYYY HH:mm:ss') }} por {{ confirmedBy(value) }}
              </span>
              <div
                v-if="value.verified_at === null"
                class="d-flex flex-column align-items-start"
              >
                <b-button
                  size="sm"
                  :disabled="value.isSubmitingConfirmation"
                  @click="sendConfirmationEmail(value)"
                >
                  <b-spinner
                    v-if="value.isSubmitingConfirmation"
                    small
                  />
                  <feather-icon
                    v-else
                    animation="spin-pulse"
                    icon="PlusSquareIcon"
                  />
                  Enviar e-mail de confirmação
                </b-button>
                <small v-if="value.verification_sent_at">Enviado em: {{ value.verification_sent_at | date('DD/MM/Y HH:mm:ss') }}</small>
              </div>

              <b-button
                size="sm"
                variant="outline-secondary"
                class="ml-1"
                :disabled="value.isSubmiting"
                @click="confirmEmail(value)"
              >
                <b-spinner
                  v-if="value.isSubmiting"
                  small
                />
                <feather-icon
                  v-else
                  animation="spin-pulse"
                  icon="CheckIcon"
                />
                <span v-if="value.verified_at === null">
                  Confirmar
                </span>
                <span v-else>
                  Reconfirmar
                </span>
                manualmente
              </b-button>

            </b-col>
          </b-row>
        </validation-provider>
      </validation-observer>

      <b-button
        size="sm"
        :disabled="isSubmiting"
        @click="add()"
      >
        Adicionar e-mail
      </b-button>

    </b-form-group>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MarkerCheckIcon from '@iconify/icons-mdi/marker-check'
import UserService from '@/services/userService'
import {
  BButton,
  BRow,
  BCol,
  BSpinner,
  BInputGroupAppend,
  BFormInput,
  BInputGroup,
  BFormGroup,
  VBTooltip,
} from 'bootstrap-vue'
import toast from '../../../mixins/toast'

export default {
  components: {
    BInputGroup,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BSpinner,
    BInputGroupAppend,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    toast,
  ],
  props: {
    isSubmiting: {
      type: Boolean,
      required: false,
      default: false,
    },
    emails: {
      type: Array,
      default: null,
    },
    userName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: true,
      userService: null,
      icons: {
        MarkerCheckIcon,
      },
    }
  },
  computed: {},
  created() {
    this.userService = new UserService()
  },
  methods: {
    setMasterEmail(value) {
      this.$emit('setMasterEmail', value)
    },
    confirmedBy(email) {
      if (email.verification_type === 'administration') {
        return email.account_manager.name
      }

      if (email.verification_type === 'own_user') {
        return this.userName
      }

      return 'Sistema'
    },

    add() {
      const e = JSON.parse(JSON.stringify(this.emails))
      e.push({
        email: '',
        id: '',
        conferred_at: null,
        verified_at: null,
        verification_sent_at: null,
        master: false,
      })
      this.$emit('update:emails', e)
    },

    remove(email) {
      const e = this.emails.filter(f => f.email !== email.email)
      this.$emit('update:emails', e)
    },

    changeEmail(newEmail) {
      const temp = this.emails.map(f => (f.email === newEmail.email ? newEmail : f)).map(m => ({
        ...m, isSubmiting: false, isSubmitingConfirmation: false, old: m.email,
      }))
      this.$emit('update:emails', temp)
    },

    sendConfirmationEmail(email) {
      this.$swal({
        title: 'Enviar e-mail?',
        text: 'Enviar e-mail para o usuário com link de confirmação para que ele possa confirmar o próprio e-mail?',
        icon: 'question',
        confirmButtonText: 'Sim, enviar link',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          email.isSubmitingConfirmation = true
          this.userService.sendConfirmationEmail(email.id).then(response => {
            email.isSubmitingConfirmation = false
            if (response.status) {
              this.changeEmail(response.data.data)
              this.toastSuccess('E-mail enviado!')
            } else {
              this.toastDanger('E-mail não enviado!')
            }
          })
        }
      })
    },

    confirmEmail(email) {
      this.$swal({
        title: 'Confirmar e-mail?',
        text: 'Marcar este e-mail como confirmado?',
        icon: 'question',
        confirmButtonText: 'Sim, confirmar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          email.isSubmiting = true
          this.userService.confirmEmail(email.id).then(response => {
            email.isSubmiting = false
            if (response.status) {
              this.changeEmail(response.data.data)
              this.toastSuccess('E-mail confirmado!')
            } else {
              this.toastDanger('E-mail não confirmado!')
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
  .margin-bottom{
    display: block;
    margin-bottom: 10px;
  }

  .emails-row {
    display: flex;
    align-items: center;
  }
</style>
