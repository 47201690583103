<template>
  <div class="user-edit-zones">

    <b-row>
      <b-col cols="3">
        <vue-perfect-scrollbar
          ref="refStates"
          :settings="perfectScrollbarSettings"
          class="scroll-area"
        >
          <b-list-group>
            <b-list-group-item
              v-if="searchZone === ''"
              :disabled="isSubmiting"
              @click="clickAllCountry()"
            >
              <span>
                Todo o Brasil
              </span>
            </b-list-group-item>
            <b-list-group-item
              v-for="state in states"
              :key="state.initials"
              :active="state.selected"
              class="d-flex justify-content-between"
              :disabled="isSubmiting"
              @click="clickState(state)"
            >
              {{ state.name }}
              <feather-icon
                icon="ChevronRightIcon"
                size="16"
              />
            </b-list-group-item>
          </b-list-group>
        </vue-perfect-scrollbar>
      </b-col>

      <b-col cols="4">
        <b-input-group v-if="zones.length">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="search_zone"
            v-model="searchZone"
            placeholder="Buscar"
            autocomplete="off"
            :disabled="isSubmiting"
          />
          <b-input-group-append v-if="searchZone.length">
            <b-button
              variant="outline-primary"
              :disabled="isSubmiting"
              @click="searchZone = ''"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <vue-perfect-scrollbar
          ref="refZones"
          :settings="perfectScrollbarSettings"
          class="scroll-area"
        >
          <b-list-group>
            <b-list-group-item
              v-if="searchZone === '' && states.filter(f => f.selected).length > 0"
              :disabled="isSubmiting"
              @click="clickAllState(states.filter(f => f.selected)[0])"
            >
              <span>
                Todo o Estado
              </span>
            </b-list-group-item>
            <b-list-group-item
              v-for="zone in zones.filter(zone => zone.hide === undefined || zone.hide === false)"
              :key="zone.id"
              :disabled="isSubmiting"
              @click="clickZone(zone)"
            >
              <span v-if="searchZone === ''">
                {{ zone.name }}
              </span>
              <!-- eslint-disable vue/no-v-html -->
              <span
                v-else
                v-html="highlight(zone)"
              />
            </b-list-group-item>

            <b-list-group-item
              v-for="city in showCities ? cities.filter(city => city.hide === undefined || city.hide === false) : []"
              :key="city.city_id"
              :disabled="isSubmiting"
              @click="clickCity(city)"
            >
              {{ city.name }}
            </b-list-group-item>

          </b-list-group>
        </vue-perfect-scrollbar>
      </b-col>

      <b-col cols="5">
        <vue-perfect-scrollbar
          ref="refZones"
          :settings="perfectScrollbarSettings"
          class="scroll-area"
        >
          <ul class="ul-list">
            <li
              v-for="selected in selectedZonesView"
              :key="selected.id"
              :class="selected.padding + (selected.new ? ' new' : '')"
            >
              <b-button
                variant="flat-secondary"
                class="btn-icon"
                size="sm"
                :disabled="isSubmiting"
                @click="removeSelected(selected)"
              >
                <feather-icon icon="XCircleIcon" />
              </b-button>
              {{ selected.text }}
            </li>
          </ul>
        </vue-perfect-scrollbar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ZoneService from '@/services/zoneService'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BFormInput,
  BListGroup,
  BListGroupItem,
  BButton,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BButton,
    BListGroup,
    BListGroupItem,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    VuePerfectScrollbar,
  },
  props: {
    isSubmiting: {
      type: Boolean,
      required: false,
      default: false,
    },
    preferredZones: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      zoneService: null,
      showCities: true,

      searchZone: '',
      states: [],
      zones: [],
      cities: [],
      selectedZones: [],
      initialZones: [],

      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
    }
  },
  computed: {
    selectedZonesView() {
      let { selectedZones } = this
      selectedZones = selectedZones.sort((a, b) => (a.state + a.zone_name + a.city).localeCompare(b.state + b.zone_name + b.city))

      const ret = []
      selectedZones.forEach(sel => {
        if (ret.find(f => f.state === sel.state) === undefined) {
          ret.push({
            state: sel.state,
            zone_id: '',
            zone_name: '',
            city: '',
            text: sel.state,
            padding: '',
            new: sel.new,
          })
        }

        if (ret.find(f => f.state === sel.state && f.zone_id === sel.zone_id) === undefined) {
          ret.push({
            state: sel.state,
            zone_id: sel.zone_id,
            zone_name: sel.zone_name,
            city: '',
            text: sel.zone_name,
            padding: 'ul-list-padding-1',
            new: sel.new,
          })
        }

        if (sel.city !== '') {
          ret.push({
            state: sel.state,
            zone_id: sel.zone_id,
            zone_name: sel.zone_name,
            city: sel.city,
            text: sel.city,
            padding: 'ul-list-padding-2',
            new: sel.new,
          })
        }
      })

      return ret
    },
  },
  watch: {
    selectedZones(value) {
      if (this.isLoading) {
        return
      }

      let ret = []
      value.forEach(element => {
        if (element.city_id === '') {
          ret.push(element.initials)
          ret.push(element.zone_id)
        } else {
          ret.push(element.initials)
          ret.push(element.zone_id)
          ret.push(element.city_id)
        }
      })

      ret = ret.filter(f => typeof f === 'number' || f.trim() !== '')
      this.$emit('changed', [...new Set(ret)])
    },

    searchZone(value) {
      const search = value.toLowerCase()

      if (search.length > 0) {
        this.zones = this.zones.map(zone => ({
          ...zone,
          hide: zone.name.toLowerCase().indexOf(search) === -1 && zone.cities.filter(city => (city.name.toLowerCase().indexOf(search) > -1)).length === 0,
        }))

        this.cities = this.cities.map(city => ({
          ...city,
          hide: city.name.toLowerCase().indexOf(search) === -1,
        }))
      } else {
        this.zones = this.zones.map(zone => ({
          ...zone,
          hide: false,
        }))

        this.cities = this.cities.map(city => ({
          ...city,
          hide: false,
        }))
      }
    },
  },
  created() {
    this.initialZones = this.preferredZones
    this.zoneService = new ZoneService()
    this.getZones()
  },
  methods: {
    countSelectedStates() {
      return [...new Set(this.selectedZones.map(m => m.initials))].length
    },

    highlight(zone) {
      const value = zone.name
      const reg = new RegExp(`(${this.searchZone})`, 'gi')
      const cities = zone.cities.filter(city => city.name.toLowerCase().indexOf(this.searchZone) > -1)
      const newValue = value.replace(reg, '<b>$1</b>')
      return `<span>${newValue}</span><br><small>${cities.map(city => city.name.replace(reg, '<b>$1</b>')).join(', ')}</small>`
    },

    removeFlagNew() {
      if (this.timeout !== undefined) {
        clearInterval(this.timeout)
      }
      this.timeout = setTimeout(() => { this.selectedZones = this.selectedZones.map(m => ({ ...m, new: false })) }, 2000)
    },

    setZones(zones, clear = false, markNewer = true) {
      if (clear) {
        this.selectedZones = []
      }
      this.initialZones = zones
      this.setInitialZones(markNewer)
    },

    setInitialZones(markNewer = false) {
      this.initialZones.forEach(element => {
        this.states.forEach(st => {
          if (st.initials === element) {
            this.addZone(markNewer, st.initials, st.name)
            return
          }

          // city
          st.zones.forEach(zone => {
            const findCity = zone.cities.find(f => f.id === element)
            if (findCity !== undefined) {
              this.addZone(markNewer, st.initials, st.name, zone.id, zone.name, findCity.id, findCity.name)
            } else if (zone.id === parseInt(element, 10)) {
              // zone
              this.addZone(markNewer, st.initials, st.name, zone.id, zone.name)
            }
          })
        })
      })
    },
    getZones() {
      this.zoneService.getAndStoreZones().then(response => {
        this.states = response
        this.setInitialZones()
        this.isLoading = false
      })
    },

    removeSelected(selected) {
      if (selected.city !== '') {
        this.selectedZones = this.selectedZones.filter(f => f.state !== selected.state
                    || f.zone_id !== selected.zone_id
                    || f.city !== selected.city)
      } else if (selected.zone_id !== '') {
        this.selectedZones = this.selectedZones.filter(f => f.state !== selected.state
                    || f.zone_id !== selected.zone_id)
      } else {
        this.selectedZones = this.selectedZones.filter(f => f.state !== selected.state)
      }
    },

    addZone(markNewer, stateInitials, stateName, zoneId = '', zoneName = '', cityId = '', cityName = '') {
      let find = null
      if (cityId === '' && zoneId === '') {
        find = this.selectedZones.find(f => f.initials === stateInitials)
      } else if (cityId === '') {
        find = this.selectedZones.find(f => f.initials === stateInitials && f.zone_name === zoneName)
      } else {
        find = this.selectedZones.find(f => f.initials === stateInitials && f.zone_name === zoneName && f.city_id === cityId)
      }

      if (find === undefined) {
        this.selectedZones.push({
          zone_id: zoneId,
          zone_name: zoneName,
          state: stateName,
          initials: stateInitials,
          city_id: cityId ?? '',
          city: cityName ?? '',
          new: markNewer,
        })
        this.removeFlagNew()
      }
    },
    clickAllCountry() {
      this.states.forEach(state => {
        this.addZone(true, state.initials, state.name, '', '')
      })
    },

    clickAllState(state) {
      this.addZone(true, state.initials, state.name, '', '')
    },
    clickZone(zone) {
      this.addZone(true, zone.initials, zone.state, zone.id, zone.name)
    },

    clickCity(city) {
      this.addZone(true, city.initials, city.state, city.id, city.zone, city.city_id, city.name)
    },

    clickState(state) {
      this.searchZone = ''
      this.states = this.states.map(m => ({
        ...m,
        selected: m.initials === state.initials,
      }))
      this.zones = state.zones.map(m => ({
        ...m,
        state: state.name,
        initials: state.initials,
      }))

      this.cities = []
      this.zones.forEach(zone => {
        const newCities = zone.cities.map(city => ({
          id: zone.id,
          state: zone.state,
          initials: zone.initials,
          zone: zone.name,
          city_id: city.id,
          name: city.name,
        }))

        this.cities = this.cities.concat(newCities)
      })
    },

  },
}
</script>
<style lang="scss">
  .user-edit-zones {
    .ps-container > .ps__rail-y,
    .ps-container > .ps__rail-x {
      opacity: 0.6;
    }

    span b{
      color: #7367f0;
    }

    .list-group-item.active{
      z-index: 0;
    }

    .scroll-area {
      position: relative;
      margin: auto;
      width: 100%;
      height: 450px;
    }

    .ul-list {
      list-style-type: none;
      padding-left: 0px;
    }

    ul li{
      transition-property: text-shadow, color;
      transition-duration: 2s;
      text-shadow: none;
    }

    ul li.new{
      text-shadow: 0px 0px 5px #b0a8ff;
      color: #776dde;
    }

    .ul-list-padding-1 {
      padding-left: 20px;
    }

    .ul-list-padding-2 {
      padding-left: 40px;
    }

    .list-group-item {
      cursor: pointer;
    }
  }
</style>
