<template>
  <div class="user-edit-segments">

    <b-row>
      <b-col cols="4">
        <b-input-group v-if="segments.length">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="search_segment"
            v-model="searchSegment"
            placeholder="Buscar"
            autocomplete="off"
            :disabled="isSubmiting"
          />
          <b-input-group-append v-if="searchSegment.length">
            <b-button
              variant="outline-primary"
              :disabled="isSubmiting"
              @click="searchSegment = ''"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <vue-perfect-scrollbar
          ref="refSegments"
          :settings="perfectScrollbarSettings"
          class="scroll-area"
        >
          <b-list-group>
            <b-list-group-item
              v-for="segment in segmentsRoots.filter(f => f.hide === undefined || f.hide === false)"
              :key="segment.id"
              :active="segment.selected"
              :disabled="isSubmiting"
              class="d-flex justify-content-between"
              @click="clickSegment(segment)"
            >
              {{ segment.name }}
              <feather-icon
                icon="ChevronRightIcon"
                size="16"
              />
            </b-list-group-item>
          </b-list-group>
        </vue-perfect-scrollbar>
      </b-col>

      <b-col cols="4">
        <b-input-group v-if="subSegments.length">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="search_sub_segment"
            v-model="searchSubSegment"
            placeholder="Buscar"
            autocomplete="off"
            :disabled="isSubmiting"
          />
          <b-input-group-append v-if="searchSegment.length">
            <b-button
              variant="outline-primary"
              :disabled="isSubmiting"
              @click="searchSubSegment = ''"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <vue-perfect-scrollbar
          ref="refSegments"
          :settings="perfectScrollbarSettings"
          class="scroll-area"
        >
          <b-list-group>
            <b-list-group-item
              v-for="sub in subSegments.filter(f => f.hide === undefined || f.hide === false)"
              :key="sub.id"
              :disabled="isSubmiting"
              class="d-flex justify-content-between"
              @click="clickSubSegment(sub)"
            >
              <span v-if="searchSegment === ''">
                {{ sub.name }}
              </span>
              <!-- eslint-disable vue/no-v-html -->
              <span
                v-else
                v-html="highlight(sub.name)"
              />
              <feather-icon
                icon="ChevronRightIcon"
                size="16"
              />
            </b-list-group-item>
          </b-list-group>
        </vue-perfect-scrollbar>
      </b-col>

      <b-col cols="4">
        <vue-perfect-scrollbar
          ref="refSegments"
          :settings="perfectScrollbarSettings"
          class="scroll-area user-edit-segments__selected-list"
        >
          <ul class="ul-list">
            <li
              v-for="selected in selectedSegmentsView"
              :key="selected.segmentId + '-'+selected.subSegmentId"
              :class="selected.padding + (selected.new ? ' new' : '') + (selected.level ? ` level-segment level-${selected.level}` : '')"
            >
              <b-button
                variant="flat-secondary"
                class="btn-icon"
                size="sm"
                :disabled="isSubmiting"
                @click="removeSegment(selected)"
              >
                <feather-icon icon="XCircleIcon" />
              </b-button>
              {{ selected.text }}
            </li>
          </ul>
        </vue-perfect-scrollbar>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import SegmentService from '@/services/segmentService'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BFormInput,
  BListGroup,
  BListGroupItem,
  BButton,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BButton,
    BListGroup,
    BListGroupItem,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    VuePerfectScrollbar,
  },
  props: {
    isSubmiting: {
      type: Boolean,
      required: false,
      default: false,
    },
    preferredSegments: {
      type: Array,
      default: null,
    },
    userType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      zoneService: null,

      searchSegment: '',
      searchSubSegment: '',
      segments: [],
      segmentsRoots: [],
      subSegments: [],
      selectedSegments: [],
      initialSegments: [],

      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
    }
  },
  computed: {
    selectedSegmentsView() {
      let { selectedSegments } = this
      selectedSegments = selectedSegments.sort((a, b) => (a.segmentName + a.subSegmentName).localeCompare(b.segmentName + b.subSegmentName))

      const ret = []
      selectedSegments.forEach(element => {
        if (ret.find(f => f.segmentId === element.segmentId) === undefined) {
          ret.push({
            segmentId: element.segmentId,
            subSegmentId: '',
            text: element.segmentName,
            padding: '',
            new: element.new,
            level: element.level,
          })
        }

        if (ret.find(f => f.segmentId === element.segmentId && f.subSegmentId === element.subSegmentId) === undefined) {
          ret.push({
            segmentId: element.segmentId,
            subSegmentId: element.subSegmentId,
            text: element.subSegmentName,
            padding: 'ul-list-padding-1',
            new: element.new,
            level: element.level,
          })
        }
      })

      return ret
    },
  },
  watch: {
    selectedSegments(value) {
      if (this.isLoading) {
        return
      }

      this.$emit('changed', [...new Set(value.map(m => m.segmentId).concat(value.map(m => m.subSegmentId)).filter(f => f.toString() !== ''))])
    },

    searchSegment(value) {
      const search = value.toLowerCase()

      if (search.length > 0) {
        this.segmentsRoots = this.segmentsRoots.map(seg => ({
          ...seg,
          hide: seg.name.toLowerCase().indexOf(search) === -1 && this.segments.filter(f => (f.parent_id === seg.id && f.name.toLowerCase().indexOf(search) > -1)).length === 0,
        }))
      } else {
        this.segmentsRoots = this.segmentsRoots.map(seg => ({
          ...seg,
          hide: false,
        }))
      }

      if (this.segmentsRoots.filter(f => (!f.hide)).length) {
        this.clickSegment(this.segmentsRoots.filter(f => (!f.hide))[0])
      }
    },

    searchSubSegment(value) {
      const search = value.toLowerCase()

      if (search.length > 0) {
        this.subSegments = this.subSegments.map(seg => ({
          ...seg,
          hide: seg.name.toLowerCase().indexOf(search) === -1,
        }))
      } else {
        this.subSegments = this.subSegments.map(seg => ({
          ...seg,
          hide: false,
        }))
      }
    },
  },
  created() {
    this.initialSegments = this.preferredSegments
    this.segmentService = new SegmentService()
    this.getSegments()
  },
  methods: {
    countSelectedSegments() {
      return [...new Set(this.selectedSegments.map(m => m.segmentId))].length
    },

    highlight(value) {
      const reg = new RegExp(`(${this.searchSegment})`, 'gi')
      return value.replace(reg, '<b>$1</b>')
    },

    setSegments(segments, clear = false, markNewer = true) {
      if (clear) {
        this.selectedSegments = []
      }
      this.initialSegments = segments
      this.setInitialSegments(markNewer)
    },

    setInitialSegments(markNewer = false) {
      this.initialSegments.forEach(element => {
        const find = this.segments.find(f => f.id === parseInt(element, 10))
        if (find && find.parent_id !== null) {
          const father = this.segmentsRoots.find(f => f.id === find.parent_id)
          this.selectedSegments.push({
            segmentName: father.name,
            segmentId: find.parent_id,
            subSegmentName: find.name,
            subSegmentId: find.id,
            new: markNewer,
            level: this.userType === 'company' ? father.repLevel : father.companyLevel,
            tags: find.tags,
            automaticSearchTags: find.automatic_search_tags,
            fatherAutomaticSearchTags: father.automatic_search_tags,
          })
        } else if (find && find.parent_id === null) {
          this.selectedSegments.push({
            segmentName: find.name,
            segmentId: find.id,
            subSegmentName: '',
            subSegmentId: '',
            new: markNewer,
            level: this.userType === 'company' ? find.repLevel : find.companyLevel,
            tags: find.tags,
            automaticSearchTags: find.automatic_search_tags,
            fatherAutomaticSearchTags: [],
          })
        }
      })

      this.removeFlagNew()
    },

    removeFlagNew() {
      if (this.timeout !== undefined) {
        clearInterval(this.timeout)
      }
      this.timeout = setTimeout(() => { this.selectedSegments = this.selectedSegments.map(m => ({ ...m, new: false })) }, 2000)
    },

    getSegments() {
      this.segmentService.getAndStoreSegments().then(response => {
        this.segments = response
        this.segmentsRoots = this.segments.filter(f => f.parent_id === null)
        this.segmentsRoots = this.segmentsRoots.sort((a, b) => a.name.localeCompare(b.name))

        this.setInitialSegments()

        this.isLoading = false
      })
    },

    clickSegment(segment) {
      this.searchSubSegment = ''
      this.segmentsRoots = this.segmentsRoots.map(m => ({
        ...m,
        selected: m.id === segment.id,
      }))
      this.subSegments = this.segments.filter(f => f.parent_id === segment.id).map(m => ({
        ...m,
        segment,
      }))

      this.subSegments = this.subSegments.sort((a, b) => a.name.localeCompare(b.name))
    },

    clickSubSegment(subSegment) {
      const find = this.selectedSegments.find(f => f.segmentId === subSegment.segment.id && f.subSegmentId === subSegment.id)
      if (!find) {
        const father = this.segmentsRoots.find(f => f.id === subSegment.parent_id)
        this.selectedSegments.push({
          segmentName: father.name,
          segmentId: father.id,
          subSegmentName: '',
          subSegmentId: '',
          new: true,
          level: this.userType === 'company' ? father.repLevel : father.companyLevel,
          tags: '',
          automaticSearchTags: father.automatic_search_tags,
          fatherAutomaticSearchTags: [],
        })

        this.selectedSegments.push({
          segmentName: subSegment.segment.name,
          segmentId: subSegment.segment.id,
          subSegmentName: subSegment.name,
          subSegmentId: subSegment.id,
          new: true,
          level: this.userType === 'company' ? father.repLevel : father.companyLevel,
          tags: subSegment.tags,
          automaticSearchTags: subSegment.automatic_search_tags,
          fatherAutomaticSearchTags: father.automatic_search_tags,
        })
        this.removeFlagNew()
      }
    },

    removeSegment(subSegment) {
      if (subSegment.subSegmentId === '') {
        this.selectedSegments = this.selectedSegments.filter(f => f.segmentId !== subSegment.segmentId)
      } else {
        this.selectedSegments = this.selectedSegments.filter(f => f.segmentId !== subSegment.segmentId || f.subSegmentId !== subSegment.subSegmentId)
      }
    },

  },
}
</script>
<style lang="scss">
  .user-edit-segments {
    span b{
      color: #7367f0;
    }

    .list-group-item.active{
      z-index: 0;
    }

    .scroll-area {
      position: relative;
      margin: auto;
      width: 100%;
      height: 450px;
    }

    .ul-list {
      list-style-type: none;
      padding-left: 0px;
    }

    ul li{
      transition-property: text-shadow;
      transition-duration: 2s;
      text-shadow: none;
    }

    ul li.new{
      text-shadow: 0px 0px 5px #7367f0;
    }

    .ul-list-padding-1 {
      padding-left: 20px;
    }

    .ul-list-padding-2 {
      padding-left: 40px;
    }

    .list-group-item {
      cursor: pointer;
    }

    &__selected-list {
      .level-segment {
        text-shadow: 0 0 1px;
      }

      .level-1 {
        color: green;
      }

      .level-2 {
        color: gold;
      }

      .level-3 {
        color: orangered;
      }

      .level-4 {
        color: red;
      }
    }
  }
</style>
