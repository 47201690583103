<template>
  <div class="user-edit-phones">
    <b-form-group
      label="Telefones adicionais"
      label-for="phones"
    >
      <validation-observer ref="simpleRules">
        <validation-provider
          v-for="(value, i) in phones.filter(f => !f.master)"
          #default="{ errors }"
          :key="i"
          rules="required"
          class="margin-bottom"
        >
          <b-row class="phones-row">
            <b-col cols="4">
              <b-input-group>
                <cleave
                  :id="'additional_phone'+i"
                  v-model="value.complete"
                  class="form-control"
                  placeholder="DDD + Número"
                  :disabled="isSubmiting || value.isSubmiting"
                  :options="value.complete.match(/[0-9]*/g).join('').length == 10 ? maskPhone8 : maskPhone9"
                />

                <b-input-group-append>
                  <b-button
                    v-b-tooltip.hover.noninteractive="'Iniciar ligação com VOIP'"
                    variant="outline-primary"
                    :href="`callto:0${value.complete.match(/[0-9]*/g).join('')}`"
                  >
                    <feather-icon icon="PhoneCallIcon" />
                  </b-button>
                  <b-button
                    v-b-tooltip.hover.noninteractive="'Definir como principal'"
                    variant="outline-info"
                    :disabled="isSubmiting || value.isSubmiting"
                    size="sm"
                    @click="setMasterPhone(value)"
                  >
                    <Iconify
                      :icon="icons.MarkerCheckIcon"
                      width="14"
                      height="14"
                    />
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    :disabled="isSubmiting || value.isSubmiting"
                    size="sm"
                    @click="remove(value)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-col>

            <b-col
              v-if="value.complete !== value.old || value.id === '' || value === null"
              cols="8"
            >
              <span>Salve os dados antes de enviar o WhatsApp de confirmação</span>
            </b-col>
            <b-col
              v-else
              cols="8"
              class="d-flex flex-wrap align-items-center"
            >
              <span
                v-if="value.conferred_at !== null"
                class="ml-1 text-success"
              >
                <feather-icon
                  animation="spin-pulse"
                  icon="CheckIcon"
                />
                Conferido em {{ value.conferred_at | date('DD/MM/YYYY HH:mm:ss') }} por {{ userName }}
              </span>
              <span
                v-if="value.verified_at !== null"
                class="ml-1 text-success"
              >
                <feather-icon
                  animation="spin-pulse"
                  icon="CheckIcon"
                />
                Confirmado em {{ value.verified_at | date('DD/MM/YYYY HH:mm:ss') }} por {{ confirmedBy(value) }}
              </span>

              <div class="d-flex flex-column align-items-start">
                <b-button
                  v-if="value.verified_at === null"
                  size="sm"
                  :disabled="value.isSubmitingConfirmation"
                  @click="sendConfirmationPhone(value)"
                >
                  <b-spinner
                    v-if="value.isSubmitingConfirmation"
                    small
                  />
                  <feather-icon
                    v-else
                    animation="spin-pulse"
                    icon="PlusSquareIcon"
                  />
                  Enviar WhatsApp de confirmação
                </b-button>
                <small v-if="value.verified_at === null && value.verification_sent_at">Enviado em: {{ value.verification_sent_at | date('DD/MM/Y HH:mm:ss') }}</small>
              </div>

              <b-button
                size="sm"
                variant="outline-secondary"
                class="ml-1"
                :disabled="value.isSubmiting"
                @click="confirmPhone(value)"
              >
                <b-spinner
                  v-if="value.isSubmiting"
                  small
                />
                <feather-icon
                  v-else
                  animation="spin-pulse"
                  icon="CheckIcon"
                />
                <span v-if="value.verified_at === null">
                  Confirmar
                </span>
                <span v-else>
                  Reconfirmar
                </span>
                manualmente
              </b-button>

            </b-col>
          </b-row>
        </validation-provider>
      </validation-observer>

      <b-button
        size="sm"
        :disabled="isSubmiting"
        @click="add()"
      >
        Adicionar telefone
      </b-button>

    </b-form-group>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MarkerCheckIcon from '@iconify/icons-mdi/marker-check'
import UserService from '@/services/userService'
import { maskPhone8, maskPhone9 } from '@/utils/masks'
import {
  BButton,
  BCol,
  BRow,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
  BFormGroup,
  VBTooltip,
} from 'bootstrap-vue'
import toast from '../../../mixins/toast'

export default {
  components: {
    BInputGroup,
    BButton,
    BInputGroupAppend,
    BFormGroup,
    BSpinner,
    BCol,
    BRow,
    Cleave,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    toast,
  ],
  props: {
    isSubmiting: {
      type: Boolean,
      required: false,
      default: false,
    },
    phones: {
      type: Array,
      default: null,
    },
    userName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: true,
      maskPhone8,
      maskPhone9,
      icons: {
        MarkerCheckIcon,
      },
    }
  },
  computed: {},
  created() {
    this.userService = new UserService()
  },
  methods: {
    confirmedBy(phone) {
      if (phone.verification_type === 'administration') {
        return phone.account_manager.name
      }

      if (phone.verification_type === 'own_user') {
        return this.userName
      }

      return 'Sistema'
    },

    add() {
      const e = JSON.parse(JSON.stringify(this.phones))
      e.push({
        phone: '',
        complete: '',
        id: '',
        verified_at: null,
        conferred_at: null,
        verification_sent_at: null,
        master: false,
      })
      this.$emit('update:phones', e)
    },

    remove(value) {
      const e = this.phones.filter(f => f !== value)
      this.$emit('update:phones', e)
    },

    setMasterPhone(value) {
      this.$emit('setMasterPhone', value)
    },

    changePhone(newPhone) {
      const temp = this.phones.map(f => (f.complete === newPhone.complete ? newPhone : f)).map(m => ({
        ...m, isSubmiting: false, isSubmitingConfirmation: false, old: m.complete, user: null,
      }))
      this.$emit('update:phones', temp)
    },

    sendConfirmationPhone(phone) {
      this.$swal({
        title: 'Enviar WhatsApp?',
        text: 'Enviar WhatsApp para o usuário pedindo a confirmação de seu número de telefone?',
        icon: 'question',
        confirmButtonText: 'Sim, enviar confirmação',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          phone.isSubmitingConfirmation = true
          this.userService.sendConfirmationWhatsApp(phone.id).then(response => {
            phone.isSubmitingConfirmation = false
            if (response.status) {
              this.changePhone(response.data.data)
              this.toastSuccess('WhatsApp enviado!')
            } else {
              this.toastDanger('WhatsApp não enviado!')
            }
          })

          this.userEditing.phone_master.isSubmitingConfirmation = true
          this.userService.sendConfirmationWhatsApp(this.userEditing.phone_master.id).then(response => {
            this.userEditing.phone_master.isSubmitingConfirmation = false
            if (response.status) {
              this.toast('success', 'WhatsApp enviado!')
              this.changePhone(response.data.data)
            } else {
              this.toast('danger', 'Ops!', 'WhatsApp não enviado')
            }
          })
        }
      })
    },

    confirmPhone(phone) {
      this.$swal({
        title: 'Confirmar Telefone?',
        text: 'Marcar este telefone  como confirmado?',
        icon: 'question',
        confirmButtonText: 'Sim, confirmar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          phone.isSubmiting = true
          this.userService.confirmWhatsApp(phone.id).then(response => {
            phone.isSubmiting = false
            if (response.status) {
              this.changePhone(response.data.data)
              this.toastSuccess('Telefone confirmado!')
            } else {
              this.toastDanger('Telefone não confirmado!')
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
  .margin-bottom{
    display: block;
    margin-bottom: 10px;
  }

  .phones-row {
    display: flex;
    align-items: center;
  }
</style>
