var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{class:{'no-padding': _vm.noPadding}},[_c('b-modal',{ref:"audits",attrs:{"title":"Histórico","size":"lg","ok-only":""}},[_c('audits',{attrs:{"auditable-type":"demographic","auditable-id":_vm.userId,"no-title":true}})],1),(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c('b-spinner'),_c('p',[_vm._v("Carregando...")])],1):_vm._e(),(!_vm.isLoading)?_c('b-form',[_c('validation-observer',{ref:"rulesDemographic"},[_c('b-row',[_c('b-col',[_c('h4',[_vm._v("Demográfico")])]),_c('b-col',{staticClass:"text-right"},[_c('b-button',{on:{"click":_vm.openAudits}},[_vm._v(" Histórico ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Falei com","label-for":"talked_with","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"talked_with","options":_vm.userDemographicsTalkedWith,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},on:{"change":_vm.selectedTalkedWith},model:{value:(_vm.results.talked_with),callback:function ($$v) {_vm.$set(_vm.results, "talked_with", $$v)},expression:"results.talked_with"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3228945786)})],1),_c('b-form-group',{attrs:{"label":"Responsável","label-for":"responsible","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":_vm.sidebarRole === 'sales' ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isSubmiting},model:{value:(_vm.results.responsible),callback:function ($$v) {_vm.$set(_vm.results, "responsible", $$v)},expression:"results.responsible"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4089424683)})],1),(_vm.results.talked_with === 'other')?_c('b-form-group',{attrs:{"label":"Cargo","label-for":"position_in_company","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":_vm.sidebarRole === 'sales' ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isSubmiting},model:{value:(_vm.results.position_in_company),callback:function ($$v) {_vm.$set(_vm.results, "position_in_company", $$v)},expression:"results.position_in_company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1413851990)})],1):_vm._e(),(_vm.userType === 'company')?_c('b-form-group',{attrs:{"label":"Estrutura Comercial (quantas pessoas dedicadas no Dept. Comercial)?","label-for":"commercial_team_size","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":_vm.sidebarRole === 'sales' ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"commercial_team_size","options":_vm.userDemographicCommercialTeamSize,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.results.commercial_team_size),callback:function ($$v) {_vm.$set(_vm.results, "commercial_team_size", $$v)},expression:"results.commercial_team_size"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,867025641)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":_vm.sidebarRole === 'sales' ? 'Anotação do contato' : 'Notas',"label-for":"notes","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"notes","state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting,"rows":"3","max-rows":"8"},model:{value:(_vm.results.notes),callback:function ($$v) {_vm.$set(_vm.results, "notes", $$v)},expression:"results.notes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1149247343)})],1),(_vm.sidebarRole === '')?_c('hr'):_vm._e(),(_vm.sidebarRole === '')?_c('h3',[_vm._v(" Exclusivo de Sucesso ")]):_vm._e(),(['relationship', 'success', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Objetivos","label-for":"objective","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":_vm.sidebarRole === 'sales' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"objective","options":_vm.userDemographicObjectives,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.results.objective),callback:function ($$v) {_vm.$set(_vm.results, "objective", $$v)},expression:"results.objective"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3760450481)}),(_vm.results.objective === 'other')?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Qual objetivo?","disabled":_vm.isSubmiting,"state":errors.length > 0 ? false:null},model:{value:(_vm.results.objective_other),callback:function ($$v) {_vm.$set(_vm.results, "objective_other", $$v)},expression:"results.objective_other"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,946179595)})],1):_vm._e()],1):_vm._e(),(_vm.userType === 'company' && ['relationship', 'success', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Tamanho da empresa","label-for":"company_size","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":_vm.sidebarRole === 'sales' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"company_size","options":_vm.userDemographicCompanySize,"disabled":_vm.isSubmiting,"state":errors.length > 0 ? false:null},model:{value:(_vm.results.company_size),callback:function ($$v) {_vm.$set(_vm.results, "company_size", $$v)},expression:"results.company_size"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2447972719)})],1):_vm._e(),(_vm.userType === 'company' && ['relationship', 'success', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Empresa tem experiência com Representantes?","label-for":"experience_with_representatives","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":_vm.sidebarRole === 'sales' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"experience_with_representatives","options":_vm.userDemographicExperienceWithRepresentatives,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.results.experience_with_representatives),callback:function ($$v) {_vm.$set(_vm.results, "experience_with_representatives", $$v)},expression:"results.experience_with_representatives"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1155584785)})],1):_vm._e(),(_vm.userType === 'representative' && ['relationship', 'success', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Experiência com representação","label-for":"experience_with_representation","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":_vm.sidebarRole === 'sales' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"experience_with_representation","options":_vm.representativeDemographicExperienceWithRepresentation,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.results.experience_with_representation),callback:function ($$v) {_vm.$set(_vm.results, "experience_with_representation", $$v)},expression:"results.experience_with_representation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,524812658)})],1):_vm._e(),(_vm.userType === 'representative' && _vm.results.experience_with_representation !== 'without_experience' && ['relationship', 'success', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Empresa Constituída","label-for":"constituted_company","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":_vm.sidebarRole === 'sales' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"constituted_company","options":_vm.representativeDemographicConstitutedCompany,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.results.constituted_company),callback:function ($$v) {_vm.$set(_vm.results, "constituted_company", $$v)},expression:"results.constituted_company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2919543600)})],1):_vm._e(),(_vm.userType === 'representative' && _vm.results.experience_with_representation !== 'without_experience' && ['relationship', 'success', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Core","label-for":"core","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":_vm.sidebarRole === 'sales' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"core","options":_vm.representativeDemographicCore,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.results.core),callback:function ($$v) {_vm.$set(_vm.results, "core", $$v)},expression:"results.core"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3845097524)})],1):_vm._e(),(_vm.userType === 'representative' && _vm.results.experience_with_representation !== 'without_experience' && ['relationship', 'success', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Quantidade de representadas(pastas)","label-for":"quantity_represented","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":_vm.sidebarRole === 'sales' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"quantity_represented","options":_vm.representativeDemographicQuantityRepresented,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.results.quantity_represented),callback:function ($$v) {_vm.$set(_vm.results, "quantity_represented", $$v)},expression:"results.quantity_represented"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1562399177)})],1):_vm._e(),(_vm.userType === 'representative' && _vm.results.experience_with_representation !== 'without_experience' && ['relationship', 'success', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Carteira de clientes","label-for":"portfolio","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":_vm.sidebarRole === 'sales' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"portfollio","options":_vm.representativeDemographicPortfolio,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.results.portfolio),callback:function ($$v) {_vm.$set(_vm.results, "portfolio", $$v)},expression:"results.portfolio"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2332832089)})],1):_vm._e(),((_vm.userType === 'company' || _vm.results.experience_with_representation !== 'without_experience') && ['relationship', 'success', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"CRM?","label-for":"crm","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":_vm.sidebarRole === 'sales' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"crm","options":_vm.userDemographicCrm,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.results.crm),callback:function ($$v) {_vm.$set(_vm.results, "crm", $$v)},expression:"results.crm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2314663409)}),(_vm.results.crm === '1')?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"4"}},[_c('b-form-input',{attrs:{"id":"crm_detail","placeholder":"Qual CRM?","disabled":_vm.isSubmiting},model:{value:(_vm.results.crm_detail),callback:function ($$v) {_vm.$set(_vm.results, "crm_detail", $$v)},expression:"results.crm_detail"}})],1):_vm._e()],1):_vm._e(),(['relationship', 'success', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Maturidade Digital do responsável","label-for":"digital_maturity","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":_vm.sidebarRole === 'sales' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"digital_maturity","options":_vm.userDemographicDigitalMaturity,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.results.digital_maturity),callback:function ($$v) {_vm.$set(_vm.results, "digital_maturity", $$v)},expression:"results.digital_maturity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,158321420)})],1):_vm._e(),(['relationship', 'success', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Aderência à nossa proposta","label-for":"adherence_to_the_proposal","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":_vm.sidebarRole === 'sales' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"adherence_to_the_proposal","options":_vm.userDemographicAdherenceToTheProposal,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.results.adherence_to_the_proposal),callback:function ($$v) {_vm.$set(_vm.results, "adherence_to_the_proposal", $$v)},expression:"results.adherence_to_the_proposal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3826941044)})],1):_vm._e(),(_vm.sidebarRole === '')?_c('hr'):_vm._e(),(_vm.sidebarRole === '')?_c('h3',[_vm._v(" Exclusivo de Vendas ")]):_vm._e(),(['sales', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Momento comercial","label-for":"commercial_moment","label-cols-md":_vm.labelCols}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"commercial_moment","state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting,"rows":"3","max-rows":"8"},model:{value:(_vm.results.commercial_moment),callback:function ($$v) {_vm.$set(_vm.results, "commercial_moment", $$v)},expression:"results.commercial_moment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1438576815)})],1):_vm._e(),(['sales', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Dores","label-for":"pains","label-cols-md":_vm.labelCols}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"pains","state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting,"rows":"3","max-rows":"8"},model:{value:(_vm.results.pains),callback:function ($$v) {_vm.$set(_vm.results, "pains", $$v)},expression:"results.pains"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,303126127)})],1):_vm._e(),(['sales', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Crescimento/Substituição","label-for":"growth_replacement","label-cols-md":_vm.labelCols}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"growth_replacement","options":_vm.userDemographicGrowthReplacement,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.results.growth_replacement),callback:function ($$v) {_vm.$set(_vm.results, "growth_replacement", $$v)},expression:"results.growth_replacement"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3347249346)})],1):_vm._e(),(['sales', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Planejamento","label-for":"planning","label-cols-md":_vm.labelCols}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"planning","options":_vm.userDemographicPlanning,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.results.planning),callback:function ($$v) {_vm.$set(_vm.results, "planning", $$v)},expression:"results.planning"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3780897392)})],1):_vm._e(),(['sales', ''].includes(_vm.sidebarRole))?_c('b-form-group',{attrs:{"label":"Percepção de Urgência","label-for":"is_urgent","label-cols-md":_vm.labelCols}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"is_urgent","options":_vm.userDemographicIsUrgent,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting},model:{value:(_vm.results.is_urgent),callback:function ($$v) {_vm.$set(_vm.results, "is_urgent", $$v)},expression:"results.is_urgent"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2220230728)})],1):_vm._e()],1)],1),(_vm.saveButton)?_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"align":"right","variant":"primary","type":"submit","disabled":_vm.isSubmiting || _vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.updateDemographic()}}},[_vm._v(" Salvar "),(_vm.isSubmiting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }