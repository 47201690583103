var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c('b-spinner'),_c('p',[_vm._v("Carregando...")])],1):_vm._e(),(!_vm.isLoading)?_c('b-form',[_c('validation-observer',{ref:"rulesPrimary"},[_c('h4',[_vm._v("Informações Fiscais")]),_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"name"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":_vm.isSubmiting},model:{value:(_vm.taxData.name),callback:function ($$v) {_vm.$set(_vm.taxData, "name", $$v)},expression:"taxData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3327657907)})],1)],1),_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label":"CPF","label-for":"cpf"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"cpf","disabled":_vm.isSubmiting,"placeholder":"000.000.000-00","options":_vm.maskCpf},model:{value:(_vm.taxData.cpf),callback:function ($$v) {_vm.$set(_vm.taxData, "cpf", $$v)},expression:"taxData.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2932892122)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label":"Empresa","label-for":"company"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company","disabled":_vm.isSubmiting},model:{value:(_vm.taxData.company),callback:function ($$v) {_vm.$set(_vm.taxData, "company", $$v)},expression:"taxData.company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1364057395)})],1)],1),_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label":"CNPJ","label-for":"cnpj"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"cnpj","disabled":_vm.isSubmiting,"placeholder":"00.000.000/0000-00","options":_vm.maskCnpj},model:{value:(_vm.taxData.cnpj),callback:function ($$v) {_vm.$set(_vm.taxData, "cnpj", $$v)},expression:"taxData.cnpj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,833893511)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label":"E-mail","label-for":"email"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","disabled":_vm.isSubmiting},model:{value:(_vm.taxData.email),callback:function ($$v) {_vm.$set(_vm.taxData, "email", $$v)},expression:"taxData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1442940051)})],1)],1),_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label":"Telefone/Celular","label-for":"cellphone"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"cellphone","placeholder":"DDD + Número","options":_vm.taxData.cell_phone.match(/[0-9]*/g).join('').length == 10 ? _vm.maskPhone8 : _vm.maskPhone9,"disabled":_vm.isSubmiting},model:{value:(_vm.taxData.cell_phone),callback:function ($$v) {_vm.$set(_vm.taxData, "cell_phone", $$v)},expression:"taxData.cell_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1338126792)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('zip-code-input',{attrs:{"zip-code":_vm.taxData.zip_code,"is-submiting":_vm.isSubmiting},on:{"update:zipCode":function($event){return _vm.$set(_vm.taxData, "zip_code", $event)},"update:zip-code":function($event){return _vm.$set(_vm.taxData, "zip_code", $event)},"response":_vm.zipCodeResponse}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Estado","label-for":"state"}},[_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"state","options":_vm.userStates,"state":errors.length > 0 ? false:null,"disabled":_vm.isSubmiting || _vm.isLoading},on:{"change":function($event){return _vm.selectState()}},model:{value:(_vm.taxData.state),callback:function ($$v) {_vm.$set(_vm.taxData, "state", $$v)},expression:"taxData.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3842895907)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Cidade","label-for":"registration_info_city"}},[_c('validation-provider',{attrs:{"rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"city","label":"title","taggable":"","push-tags":"","value":_vm.taxData.city,"options":_vm.cityOptions,"disabled":_vm.isSubmiting || _vm.isLoading},on:{"input":_vm.selectCity}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,728018181)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Bairro","label-for":"neighbor"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"neighbor","disabled":_vm.isSubmiting},model:{value:(_vm.taxData.neighbor),callback:function ($$v) {_vm.$set(_vm.taxData, "neighbor", $$v)},expression:"taxData.neighbor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1820075923)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Rua","label-for":"street"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"street","disabled":_vm.isSubmiting},model:{value:(_vm.taxData.street),callback:function ($$v) {_vm.$set(_vm.taxData, "street", $$v)},expression:"taxData.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4013034483)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Número","label-for":"number"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"number","disabled":_vm.isSubmiting},model:{value:(_vm.taxData.number),callback:function ($$v) {_vm.$set(_vm.taxData, "number", $$v)},expression:"taxData.number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2044429363)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Complemento","label-for":"complement"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"complement","disabled":_vm.isSubmiting},model:{value:(_vm.taxData.complement),callback:function ($$v) {_vm.$set(_vm.taxData, "complement", $$v)},expression:"taxData.complement"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2027295635)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-left",attrs:{"cols":"10"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.copyData}},[_vm._v(" Copiar dados do Cadastro "),(_vm.isSubmiting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary"},on:{"click":_vm.copyDataToNf}},[_vm._v(" Copiar dados para o E-notas ")])],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('b-button',{attrs:{"disabled":_vm.isSubmiting,"variant":"primary"},on:{"click":_vm.submit}},[_vm._v(" Salvar "),(_vm.isSubmiting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }