<template>
  <div>
    <b-card>
      <b-card-title v-if="noTitle === false">
        Histórico
      </b-card-title>

      <div
        v-if="isLoading"
        class="text-center"
      >
        <b-spinner />
      </div>

      <div v-else>
        <b-row>
          <b-col cols="6">
            <b-form-select
              v-model="auditId"
              :options="auditsOptions"
              class="mb-1"
            />
          </b-col>
          <b-col
            cols="6"
            class="text-left"
          >
            <b-button
              size="sm mr-1"
              @click="previous()"
            >
              Anterior
            </b-button>
            <b-button
              size="sm mr-1"
              @click="next()"
            >
              Próximo
            </b-button>
            <b-button
              size="sm mr-1"
              @click="last()"
            >
              Último
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-table
              responsive
              :items="auditsDiff"
              :fields="result_fields"
              show-empty
              no-local-sorting
            >
              <template #empty>
                <div class="text-center mt-3 mb-3">
                  <span>Nenhum histórico disponível</span>
                </div>
              </template>

              <template #head(field)>
                Campo
              </template>

              <template #head(changes)>
                Alteração
              </template>

              <template v-slot:cell(changes)="data">
                <span
                  v-if="data.item.right !== undefined"
                  class="text-success"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                  />
                  {{ data.item.right }}
                </span>
                <span v-else>{{ data.item.left }}</span>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right">
            <span v-if="actualAudit && actualAudit.manager">
              Alterado por: {{ actualAudit.manager.name }} em {{ actualAudit.created_at | date('DD/MM/YYYY HH:mm:ss') }}
            </span>
          </b-col>
        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BCard,
  BCardTitle,
  BFormSelect,
  BTable,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import Audits from '@/services/auditsService'
import moment from 'moment/moment'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import toast from '../../../mixins/toast'

export default {
  components: {
    BButton,
    BSpinner,
    BTable,
    BCol,
    BCard,
    BCardTitle,
    BFormSelect,
    BRow,
  },

  mixins: [
    toast,
  ],

  props: {
    auditableType: {
      type: String,
      required: true,
    },

    auditableId: {
      type: Number,
      required: true,
    },

    noTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    const { t, tc } = useI18nUtils()

    return {
      isLoading: false,
      audits: [],
      auditId: 0,
      translatePathModel: '',
      translatePathConstants: '',
      result_fields: [
        'field',
        'changes',
      ],
      auditsOptions: [],
      t,
      tc,
    }
  },

  computed: {
    actualAudit() {
      return this.audits.find(f => f.id === this.auditId)
    },

    auditsDiff() {
      if (this.audits.length === 0) {
        return []
      }

      const allFields = {}
      this.audits.forEach(item => {
        if (item.id <= this.auditId) {
          Object.entries(item.new_values).forEach(item2 => {
            const field = item2[0]
            const value = item2[1]

            // garante que o field exista no array allFields]
            // assim a atribução do próximo IF pode ser feita sem erros
            if (allFields[field] === undefined) {
              allFields[field] = {
                field,
                left: '',
                right: undefined,
              }
            }

            // se for o audit selecionado, adiciona no lado direito
            if (item.id === this.auditId) {
              allFields[field].right = value
            } else {
              // toodos os outros audits são acumulados no lado esquerdo,
              // para ter sempre a edição mais recente preenchida
              allFields[field].left = value
            }
          })
        }
      })

      // traduz o cabecalho(field) e também os itens conforme as constantes do php
      return Object.entries(allFields).map(item => ({
        field: this.td(`${this.translatePathModel}.${item[0]}`, item[0]),
        left: this.td(`${this.translatePathConstants}.${item[0]}.${item[1].left}`, item[1].left),
        right: this.td(`${this.translatePathConstants}.${item[0]}.${item[1].right}`, item[1].right),
      }))
    },
  },

  created() {
    // define o caminho para buscar em portugues:
    //  - o nome dos campos (ex: name -> nome, type -> tipo)
    //  - as constantes de preenchimento dos campos (ex: PermissionType::COMPANY -> Empresa)
    if (this.auditableType === 'demographic') {
      this.translatePathModel = 'audits.user_demographic'
      this.translatePathConstants = 'user_demographics'
    }

    this.getAudits()
  },

  methods: {
    // pega o texto traduzido ou usa o default de fallbackStr
    td(str, fallbackStr) {
      if (this.$t && this.$te) {
        return this.$te(str) ? this.$t(str) : fallbackStr
      }

      return fallbackStr || str
    },

    previous() {
      const index = this.auditsOptions.findIndex(f => f.value === this.auditId)
      if (index > 0) {
        this.auditId = this.auditsOptions[index - 1].value
      }
    },

    next() {
      const index = this.auditsOptions.findIndex(f => f.value === this.auditId)
      if (index < this.auditsOptions.length - 1) {
        this.auditId = this.auditsOptions[index + 1].value
      }
    },

    last() {
      this.auditId = this.auditsOptions[this.auditsOptions.length - 1].value
    },

    getAudits() {
      this.isLoading = true
      this.auditId = 0

      Audits.get(this.auditableType, this.auditableId).then(response => {
        if (response.data.data.audits && response.data.data.audits.length > 0) {
          this.audits = response.data.data.audits
          this.auditsOptions = this.audits.map(m => ({
            text: `${moment(m.created_at).format('DD/MM/YYYY')} - ${m.manager.name}`,
            value: m.id,
          }))

          if (response.data.data.audits.length >= 2) {
            this.auditId = this.audits.slice(-1)[0].id
          } else {
            this.auditId = this.audits[0].id
          }
        } else {
          this.toastDanger('Ops!', 'Nenhum histórico disponível')
        }
      }).catch(error => {
        this.toastDanger('Ops!', Object.values(error.response.data.errors))
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
